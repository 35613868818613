import { useCallback, SyntheticEvent, MouseEvent, FocusEventHandler, FocusEvent } from "react";
import { Form, Field, FormProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { FormApi } from "final-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TitleInput,
  ButtonsWrapper,
  Switch,
  ContainedButton,
  IconButton,
  Divider,
  FeatureVisibilityWrapper
} from "shared/components";
import DeleteIcon from "@mui/icons-material/Delete";
import { ContactPersonSelect } from "../ContactPersonSelect";
import {
  ComeBackMailingTextSettingsValues,
  BrowseAbandonmentTextInitialSettingsValues,
  BrowseAbandonmentTextSettingsValues,
  ComeBackMailingTextInitialSettingsValues
} from "../../types";
import { SubmitButtonWrapper } from "../TriggerSettingsFormStyles";
import { TriggerSettingsParamsFields } from "../TriggerSettingsParamsFields";
import { TriggerSettingsConditionsFields } from "../TriggerSettingsConditionsFields";
import { titleValidator, contactPersonValidator } from "./TextSettingsFormBase.validation";
import { TextSettingsFormBaseProps } from "./TextSettingsFormBase.props";

export const TextSettingsFormBase = ({
  initialState,
  formOpened,
  expandIcon,
  translationKey,
  conditionFeatureName,
  possibleConditions,
  conditionsNotificationKey,
  onOpen,
  onClose,
  onDelete,
  onSubmit,
  onSwitchActive
}: TextSettingsFormBaseProps) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(
    (form: FormApi<BrowseAbandonmentTextSettingsValues | ComeBackMailingTextSettingsValues>) =>
      (event: SyntheticEvent) => {
        event.stopPropagation();
        if (onDelete) onDelete(initialState.id, form);
      },
    [initialState, onDelete]
  );

  const handleActivationSwitch = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      if (onSwitchActive) onSwitchActive(initialState.id, !initialState.enabled);
    },
    [initialState, onSwitchActive]
  );

  const handleFormOpen = useCallback(() => {
    if (!formOpened && onOpen) onOpen();
    if (formOpened && onClose) onClose();
  }, [formOpened, onOpen]);

  const handleTitleFocus = useCallback(
    (onFocus: FocusEventHandler<HTMLInputElement>) => (e: FocusEvent<HTMLInputElement>) => {
      if (!formOpened && onOpen) {
        onOpen();
      }
      onFocus(e);
    },
    [formOpened, onOpen]
  );

  const handleTitleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const handleFormSubmit: FormProps<
    BrowseAbandonmentTextSettingsValues | ComeBackMailingTextSettingsValues,
    BrowseAbandonmentTextInitialSettingsValues | ComeBackMailingTextInitialSettingsValues
  >["onSubmit"] = useCallback(
    (values, form) => {
      return onSubmit(values, form);
    },
    [onSubmit]
  );

  const displayConditions = conditionFeatureName && possibleConditions;

  return (
    <Form
      initialValues={initialState}
      onSubmit={handleFormSubmit}
      render={({ submitErrors, handleSubmit, submitting, dirty, form }) => (
        <Accordion expanded={formOpened} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={expandIcon} onClick={handleFormOpen}>
            <Field
              name="title"
              validate={titleValidator}
              render={({
                input: { value, onBlur, onChange, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <TitleInput
                  placeholder={t(
                    `featuresModule.${translationKey}.form.textSettings.title.placeholder`
                  )}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onClick={handleTitleClick}
                  onFocus={handleTitleFocus(onFocus)}
                  error={
                    (error || (submitErrors?.["title"] && !modifiedSinceLastSubmit)) && touched
                  }
                />
              )}
            />
            <ButtonsWrapper>
              {onSwitchActive && (
                <Switch checked={initialState.enabled} onClick={handleActivationSwitch} />
              )}
              {onDelete && (
                <IconButton disabled={submitting} onClick={handleDelete(form)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ButtonsWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <Field
              name="contactPerson"
              type="select"
              validate={contactPersonValidator}
              allowNull
              render={({
                input: { value, onBlur, onChange, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <ContactPersonSelect
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  marginBottom={24}
                  error={
                    (error || (submitErrors?.["contactPerson"] && !modifiedSinceLastSubmit)) &&
                    touched
                  }
                  errorMessage={t(submitErrors?.["contactPerson"] ?? error)}
                />
              )}
            />
            <Divider />
            <TriggerSettingsParamsFields />
            {displayConditions && (
              <FeatureVisibilityWrapper requiredFeature={conditionFeatureName}>
                <Divider />
                <TriggerSettingsConditionsFields
                  possibleConditions={possibleConditions}
                  notificationKey={conditionsNotificationKey}
                />
              </FeatureVisibilityWrapper>
            )}
            <SubmitButtonWrapper>
              <ContainedButton disabled={!dirty} loading={submitting} onClick={handleSubmit}>
                {t(`featuresModule.saveButton`)}
              </ContainedButton>
            </SubmitButtonWrapper>
          </AccordionDetails>
        </Accordion>
      )}
    ></Form>
  );
};
