import React from "react";
import { ConditionKind, ArrayConditionOperators, NumberConditionOperators } from "api/models";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useFormState, useForm, Field } from "react-final-form";
import { Alert, VisibilitySwitch } from "shared/components";
import { VisibilitySwitchWrapper, AdditionalFieldsCollapse } from "../TriggerSettingsFormStyles";
import { ConditionItem } from "./ConditionItem";
import { AddConditionButton } from "./AddConditionButton";
import { ConditionRenderer } from "./ConditionRenderer";
import { ConditionDelimeter } from "./ConditionDelimeter";
import { TriggerSettingsConditionsFieldsProps } from "./TriggerSettingsConditionsFields.props";
import {
  ConditionsObjectsInitialFormValues,
  CustomerGroupConditionFormValues,
  SettingsConditionsFormValues,
  SettingsConditionsInitialFormValues
} from "../../types";

const initialConditionsState: Record<ConditionKind, ConditionsObjectsInitialFormValues> = {
  [ConditionKind.CUSTOMER_GROUP]: {
    kind: ConditionKind.CUSTOMER_GROUP,
    operator: ArrayConditionOperators.CONTAINS,
    value: []
  },
  [ConditionKind.REVENUE]: {
    kind: ConditionKind.REVENUE,
    operator: NumberConditionOperators.LESS_OR_EQUAL,
    betweenValue: {
      less: undefined,
      more: undefined
    },
    numericValue: undefined
  }
};

const ConditionsField = Field<CustomerGroupConditionFormValues[]>;

export const TriggerSettingsConditionsFields = ({
  possibleConditions,
  notificationKey
}: TriggerSettingsConditionsFieldsProps) => {
  const { t } = useTranslation();

  const { values, errors, touched } = useFormState<SettingsConditionsFormValues>();

  const { change } = useForm<SettingsConditionsInitialFormValues>();

  const [extraOpened, setExtraOpened] = useState(false);

  const handleExtraOpenedSwitch = useCallback(() => {
    setExtraOpened((prev) => !prev);
  }, []);

  const handleAddCondition = useCallback(
    (conditionKind: ConditionKind) => {
      if (!values.conditions) {
        change("conditions", [initialConditionsState[conditionKind]]);
      } else {
        change("conditions", [...values.conditions, initialConditionsState[conditionKind]]);
      }
    },
    [values, change]
  );

  const availableConditions = useMemo(() => {
    return possibleConditions.filter((condition) => {
      return !values.conditions?.some(({ kind }) => kind === condition);
    });
  }, [possibleConditions, values]);

  const showConditionsError = useMemo(() => {
    const conditionsTouched = touched
      ? !!Object.entries(touched).find(([key, value]) => key.includes("conditions") && value)
      : false;
    return !!errors?.conditions?.length && conditionsTouched && !extraOpened;
  }, [errors?.conditions, touched, extraOpened]);

  const conditionsCount = values.conditions?.length ?? 0;

  return (
    <>
      {notificationKey && <Alert severity="warning">{t(notificationKey)}</Alert>}
      <VisibilitySwitchWrapper>
        <VisibilitySwitch
          active={extraOpened}
          error={showConditionsError}
          onClick={handleExtraOpenedSwitch}
        >
          {t("featuresModule.conditions.title")}
        </VisibilitySwitch>
      </VisibilitySwitchWrapper>
      <AdditionalFieldsCollapse in={extraOpened}>
        <ConditionsField
          name="conditions"
          render={({ input: { value } }) =>
            value.length
              ? value.map(({ kind }, index) => (
                  <React.Fragment key={kind}>
                    <ConditionRenderer kind={kind} index={index} />
                    {index < value.length - 1 && <ConditionDelimeter />}
                  </React.Fragment>
                ))
              : null
          }
        />
        {!!availableConditions.length && (
          <>
            {!!conditionsCount && <ConditionDelimeter />}
            <ConditionItem>
              <AddConditionButton
                onAddCondition={handleAddCondition}
                availableOptions={availableConditions}
              />
            </ConditionItem>
          </>
        )}
      </AdditionalFieldsCollapse>
    </>
  );
};
