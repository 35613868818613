import { apiClient } from "shared/utils";
import { QuestionnaireShopSystemStepData, ShopIdParam } from "api/models";

export const putSubmitShopSystem = async ({
  shopId,
  ...values
}: QuestionnaireShopSystemStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/shop-system`, values);

  return data;
};
