import { useCallback } from "react";
import { maxSavedColors } from "config/constants";
import { localStorage } from "../../utils";
import { useLocalStorageValue } from "../useLocalStorageValue";
import { LocalStorageKey } from "../../types";

export const useSavedColors = () => {
  const storageValue = useLocalStorageValue(LocalStorageKey.SAVED_COLORS);

  const savedColors: string[] = storageValue ? JSON.parse(storageValue) : [];

  const saveColor = useCallback(
    (color: string) => {
      if (savedColors.includes(color)) {
        return;
      }
      const newColors = [color, ...savedColors.slice(0, maxSavedColors - 1)];
      localStorage.setItem(LocalStorageKey.SAVED_COLORS, JSON.stringify(newColors));
    },
    [savedColors]
  );

  return { savedColors, saveColor };
};
