import { apiClient } from "shared/utils";
import { QuestionnaireLegalTextStepData, ShopIdParam } from "api/models";

export const putSubmitLegalText = async ({
  shopId,
  ...values
}: QuestionnaireLegalTextStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/legal-text`, values);

  return data;
};
