import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { QuestionnaireStatus, QuestionnaireStepStatus } from "api/models";
import { LoadingIndicator, TextButton } from "shared/components";
import {
  Wrapper,
  CirclesWrapper,
  CircleWrapper,
  ButtonsWrapper,
  StepName,
  StepNumber
} from "./MobileStepper.styles";
import { NumberCircle } from "../../NumberCircle";
import { useQuestionnaireStepperState } from "../../../hooks";
import { MobileStepperProps } from "./MobileStepper.props";

export const MobileStepper = ({
  nextDisabled,
  prevDisabled,
  nextLoading,
  onNextClick,
  onPrevClick
}: MobileStepperProps) => {
  const { t } = useTranslation();

  const mobile = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down("lg"));

  const { organizedSteps, selectedStep, questionnaireStatus, loading } =
    useQuestionnaireStepperState();

  const selectedTab = useMemo(
    () => organizedSteps.find(({ steps }) => steps.find(({ type }) => type === selectedStep)),
    [organizedSteps, selectedStep]
  );

  const selectedTabIndex = useMemo(
    () => (selectedTab ? selectedTab.steps.findIndex(({ type }) => type === selectedStep) + 1 : 0),
    [organizedSteps, selectedTab, selectedStep]
  );

  const navigationDisabled = useMemo(
    () => questionnaireStatus !== QuestionnaireStatus.SUBMITTED,
    [questionnaireStatus]
  );

  const handleNextClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);

  const handlePrevStep = useCallback(() => {
    onPrevClick();
  }, [onPrevClick]);

  if (!mobile) return null;

  if (loading)
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );

  return (
    <Wrapper>
      <CirclesWrapper>
        {organizedSteps.map(({ title, steps }, index) => (
          <CircleWrapper key={title}>
            <NumberCircle
              index={index + 1}
              completed={steps.every(({ status }) => status === QuestionnaireStepStatus.SUBMITTED)}
              selected={!!steps.find(({ type }) => type === selectedStep)}
            />
          </CircleWrapper>
        ))}
      </CirclesWrapper>
      {navigationDisabled && (
        <ButtonsWrapper>
          <TextButton
            variant="secondary"
            iconPosition="start"
            disabled={prevDisabled}
            onClick={handlePrevStep}
          >
            {t(`questionnaireModule.buttons.previous`)}
          </TextButton>
          <TextButton
            variant="primary"
            iconPosition="end"
            loading={nextLoading}
            disabled={nextDisabled}
            onClick={handleNextClick}
          >
            {t(`questionnaireModule.buttons.save`)}
          </TextButton>
        </ButtonsWrapper>
      )}
      {selectedTab && (
        <>
          <StepName>{t(`questionnaireModule.steps.${selectedTab.title}.title`)}</StepName>
          {selectedTabIndex && (
            <StepNumber>
              {selectedTabIndex}/{selectedTab.steps.length}
            </StepNumber>
          )}
        </>
      )}
    </Wrapper>
  );
};
