import {
  TextInput,
  ContainedButton,
  TextButton,
  Link,
  Heading,
  Subheading,
  DescriptionText,
  LoadingIndicator
} from "shared/components";
import { Form, Field } from "react-final-form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FORM_ERROR } from "final-form";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "config/navigation";
import { passwordValidator, confirmPasswordValidator } from "./SetPasswordForm.validation";
import { SetPasswordValues } from "../../types";
import { useSetPasswordMutation, useCheckResetHash } from "../../hooks";
import { FormWrapper } from "../FormWrapper";
import { FormContainer } from "../FormContainer";
import { ErrorText } from "../ErrorText";
import { ScreenImage } from "../ScreenImage";
import { BackButtonWrapper } from "./SetPasswordForm.styles";

const FORM_INITIAL_STATE: SetPasswordValues = { password: "", confirmPassword: "" };

export const SetPasswordForm = () => {
  const { t } = useTranslation();

  const { key } = useParams();

  const navigate = useNavigate();

  const { execute: submit, skipDelay } = useSetPasswordMutation(key);

  const handleBackButton = useCallback(() => {
    navigate(Routes.RESET_PASSWORD);
  }, [navigate]);

  const { valid, loading } = useCheckResetHash(key);

  const renderSubheading = useCallback((valid: boolean, success: boolean) => {
    if (!valid) {
      return t("authModule.setPasswordScreen.invalidLinkSubheading");
    }

    if (success) {
      return t("authModule.setPasswordScreen.successSubheading");
    }

    return t("authModule.setPasswordScreen.subheading");
  }, []);

  const renderDescription = useCallback(
    (valid: boolean, success: boolean) => {
      if (!valid) {
        return (
          <React.Fragment>
            <DescriptionText>
              {t("authModule.setPasswordScreen.invalidLinkDescription")}
            </DescriptionText>
            <BackButtonWrapper>
              <TextButton iconPosition="start" variant="secondary" onClick={handleBackButton}>
                {t("authModule.setPasswordScreen.backButton")}
              </TextButton>
            </BackButtonWrapper>
          </React.Fragment>
        );
      }

      if (success) {
        return (
          <DescriptionText withMargin>
            {t("authModule.setPasswordScreen.successDescription")}{" "}
            <Link onClick={skipDelay}>{t("authModule.setPasswordScreen.skipLink")}</Link>
            {"."}
          </DescriptionText>
        );
      }

      return (
        <DescriptionText withMargin>
          {t("authModule.setPasswordScreen.description")}
        </DescriptionText>
      );
    },
    [skipDelay, handleBackButton]
  );

  return (
    <FormWrapper>
      <Form
        initialValues={FORM_INITIAL_STATE}
        onSubmit={submit}
        render={({ dirty, handleSubmit, submitting, submitErrors, submitSucceeded }) => {
          const showForm = valid && !loading && !submitSucceeded;

          return (
            <React.Fragment>
              {!valid && <ScreenImage variant="setPassword" />}
              <Heading>{t("authModule.setPasswordScreen.heading")}</Heading>
              <Subheading>{renderSubheading(valid, submitSucceeded)}</Subheading>
              {renderDescription(valid, submitSucceeded)}
              {loading && <LoadingIndicator />}
              {showForm && (
                <FormContainer>
                  <Field
                    name="password"
                    validate={passwordValidator}
                    render={({
                      input: { value, onChange, onBlur, onFocus },
                      meta: { error, touched }
                    }) => (
                      <TextInput
                        password
                        placeholder="xxxxxxxxx"
                        label={t("authModule.setPasswordScreen.form.password")}
                        value={value}
                        error={error && touched}
                        errorMessage={t(error)}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Field
                    name="confirmPassword"
                    validate={confirmPasswordValidator}
                    render={({
                      input: { value, onChange, onBlur, onFocus },
                      meta: { error, touched }
                    }) => (
                      <TextInput
                        password
                        placeholder="xxxxxxxxx"
                        label={t("authModule.setPasswordScreen.form.confirmPassword")}
                        value={value}
                        error={error && touched}
                        errorMessage={t(error)}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                      />
                    )}
                  />
                  <ContainedButton
                    loading={submitting}
                    disabled={!dirty}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {t("authModule.setPasswordScreen.form.submitButton")}
                  </ContainedButton>
                  {submitErrors && <ErrorText>{t(submitErrors[FORM_ERROR])}</ErrorText>}
                </FormContainer>
              )}
            </React.Fragment>
          );
        }}
      />
    </FormWrapper>
  );
};
