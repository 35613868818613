import { useState, useCallback } from "react";
import { ConditionKind } from "api/models";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTheme, Menu, ListItemText } from "@mui/material";
import { Button, MenuItem, makePaperStyles, ListItemIcon } from "./AddConditionButton.styles";
import { AddConditionButtonProps } from "./AddConditionButton.props";

export const AddConditionButton = ({
  availableOptions,
  onAddCondition
}: AddConditionButtonProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const open = Boolean(menuAnchor);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleOptionSelect = useCallback(
    (kind: ConditionKind) => () => {
      setMenuAnchor(null);
      onAddCondition(kind);
    },
    [onAddCondition]
  );

  return (
    <>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleClick}
      >
        {t("featuresModule.conditions.addCondition")}
      </Button>
      <Menu
        open={open}
        anchorEl={menuAnchor}
        onClose={handleClose}
        MenuListProps={{
          sx: { width: menuAnchor && menuAnchor.offsetWidth }
        }}
        slotProps={{
          paper: {
            sx: makePaperStyles(theme)
          }
        }}
      >
        {availableOptions.map((option) => (
          <MenuItem key={option} onClick={handleOptionSelect(option)}>
            <ListItemText>{t(`featuresModule.conditions.kinds.${option}`)}</ListItemText>
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
