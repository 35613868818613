import { LoadingIndicator } from "shared/components";
import { useTranslation } from "react-i18next";
import { BrowseAbandonmentTextItemForm } from "../BrowseAbandonmentTextItemForm";
import { CreateBrowseAbandonmentTextForm } from "../CreateBrowseAbandonmentTextForm";
import {
  SettingsListHeader,
  SettingsListWrapper,
  LoadingWrapper
} from "../TriggerSettingsFormStyles";
import { useBrowseAbandonmentTextData } from "../../hooks";
import { DEFAULT_QUERY_LIMIT } from "config/constants";

export const BrowseAbandonmentTextSettingsList = () => {
  const { t } = useTranslation();

  const { initialState, loading } = useBrowseAbandonmentTextData();

  const browseAbandonmentTextCountThresholdMet = initialState.length >= DEFAULT_QUERY_LIMIT;

  return (
    <SettingsListWrapper>
      <SettingsListHeader>
        {t("featuresModule.browseAbandonment.form.textSettings.heading")}
      </SettingsListHeader>
      {loading ? (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        initialState.map((textSettings) => (
          <BrowseAbandonmentTextItemForm key={textSettings.id} initialState={textSettings} />
        ))
      )}
      <CreateBrowseAbandonmentTextForm
        disabled={loading || browseAbandonmentTextCountThresholdMet}
      />
    </SettingsListWrapper>
  );
};
