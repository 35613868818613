import { apiClient } from "shared/utils";

export const deleteContactPerson = async ({
  shopId,
  contactPersonId
}: {
  shopId: number;
  contactPersonId: number;
}) => {
  await apiClient.delete<void>(`${shopId}/contact-persons/${contactPersonId}`);
};
