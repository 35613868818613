import { QuestionnaireStatus } from "api/models";
import { useSelectedShop } from "../useSelectedShop";
import { useQuestionnaireData } from "./useQuestionnaireData";

export const useQuestionnaireCompleted = () => {
  const { selectedShopId } = useSelectedShop();

  const { data: questionnaireData, isLoading } = useQuestionnaireData(selectedShopId);

  const questionnaireCompleted =
    questionnaireData?.status === QuestionnaireStatus.SUBMITTED || isLoading;

  return questionnaireCompleted;
};
