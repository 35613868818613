import { useCallback } from "react";
import { QuestionnaireAlgorithmStepData, QuestionnaireStepType } from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitAlgorithm } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useAlgorithmStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.ALGORITHM);

  const data = stepData?.data;

  const submitAlgorithm =
    useQuestionnaireSubmitMutation<QuestionnaireAlgorithmStepData>(putSubmitAlgorithm);

  const executeSubmitAlgorithm: FormProps<
    QuestionnaireAlgorithmStepData,
    QuestionnaireAlgorithmStepData
  >["onSubmit"] = useCallback(
    async ({ isConfirmed }) => {
      try {
        await submitAlgorithm({
          isConfirmed
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitAlgorithm]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitAlgorithm
  };
};
