import { ContactPerson } from "api/models";
import { FieldValidator } from "final-form";
import {
  BrowseAbandonmentVoucherSettingsValues,
  ComeBackMailingVoucherSettingsValues
} from "../../types";

export const titleValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  if (value.length < 2) {
    return "featuresModule.errors.biggerThanTwoCharacters";
  }

  if (value.length > 128) {
    return "featuresModule.errors.lessThan128Characters";
  }
};

export const voucherValueValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "featuresModule.errors.shouldBeANumber";
  }

  if (numberValue < 0.1) {
    return "featuresModule.errors.tooSmall";
  }

  if (numberValue >= 1000000) {
    return "featuresModule.errors.lessThan1m";
  }
};

export const voucherCurrencyValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "featuresModule.errors.required";
  }
};

export const voucherListNameValidator: FieldValidator<string> = (value, form) => {
  if (
    (form as BrowseAbandonmentVoucherSettingsValues | ComeBackMailingVoucherSettingsValues)
      .voucherType === "LIST" &&
    !value
  ) {
    return "featuresModule.errors.required";
  }
};

export const voucherCodeValidator: FieldValidator<string> = (value, form) => {
  if (
    (form as BrowseAbandonmentVoucherSettingsValues | ComeBackMailingVoucherSettingsValues)
      .voucherType === "CODE"
  ) {
    if (!value) {
      return "featuresModule.errors.required";
    }

    if (value.length < 1) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value.length > 64) {
      return "featuresModule.errors.lessThan64Characters";
    }
  }
};

export const contactPersonValidator: FieldValidator<ContactPerson> = (value) => {
  if (!value.contactPersonId) {
    return "featuresModule.errors.required";
  }
};

export const voucherFootnoteValidator: FieldValidator<string> = (value) => {
  if (value) {
    if (value.length < 1) {
      return "featuresModule.errors.biggerThanOneCharacter";
    }

    if (value && value.length > 2048) {
      return "featuresModule.errors.lessThan2048Characters";
    }
  }
};
