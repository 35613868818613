import { PostRenderResponse, RenderRequestDesign } from "api/models";
import { RefObject } from "react";
import { DeviceType, PreviewProduct } from "shared/types";

export const desktopStyleOverrides = () => `
  .__up_activation .__up_pop.__up_hidden{
    opacity:1;
  }
    body {
    margin:0;
  }
  .__up_activation .__up_pop.__up_hidden {
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
  }
  #up_optin .__up_optin {
    transform-origin: right bottom;
  }
  .__up_activation .__up_pop .__up_action .__up_code_value {
    flex: 1 auto;
    width: auto !important;
  }
`;

export const mobileStyleOverrides = () => `
  body {
    margin: 0;
  }

  .__up_activation .__up_pop.__up_hidden {
    transform-origin: 0 0;
    opacity: 1;
  }

  .__up_activation .__up_pop .__up_action .__up_code_value {
    flex: 3 0 auto;
    width: 100px !important;
  }

  @media screen and (max-width: 200px) {
    .__up_activation .__up_h1 {
      font-size: 34px;
    }
  }
`;

// Function that apply render result to iframe
export const applyRenderResult = (
  renderResult: PostRenderResponse,
  iFrameReference: RefObject<HTMLIFrameElement>,
  design: RenderRequestDesign,
  device: DeviceType,
  previewProduct: PreviewProduct
) => {
  if (!iFrameReference.current) return;

  const iframe = iFrameReference.current;

  if (!iframe.contentDocument) return;

  const document = iframe.contentDocument;

  const head = document.getElementsByTagName("head")[0];

  const { font, fontBold, manualFont, manualFontBold } = design;

  const resultFont = font === "other" ? manualFont : font;

  const resultFontBold = fontBold === "other" ? manualFontBold : fontBold;

  if (!renderResult) return;

  const { html: resultHtml, less: resultLess, js: resultJs } = renderResult;

  // Organize fonts for insertion to <head/> of iframe
  const linkUrl = "https://fonts.googleapis.com/css?family";
  const tFont = (resultFont as string | undefined)?.replace(" ", "+") ?? "Arial";
  const hFont = (resultFontBold as string | undefined)?.replace(" ", "+") ?? "Arial";
  const rel = `rel="stylesheet"`;

  // Excluding default fonts from downloading
  const checkFont = (f: string | undefined) => {
    return f && f !== "Bookman" && f !== "Arial" && f !== "other";
  };

  // Apply additional styles to display preview correctly
  const defaultPreviewStyles =
    device === DeviceType.DESKTOP ? desktopStyleOverrides() : mobileStyleOverrides();

  // Prepare <head/> content
  const headContent = `
          <meta name="viewport" content="width=device-width, initial-scale=1">
          ${resultLess && `<style type="text/css">${resultLess}</style>`}
          <style type="text/css">${defaultPreviewStyles}</style>
          ${
            checkFont(resultFont as string | undefined)
              ? `<link href="${linkUrl}=${tFont}" ${rel} />`
              : ""
          }
          ${
            checkFont(resultFontBold as string | undefined)
              ? `<link href="${linkUrl}=${hFont}" ${rel} />`
              : ""
          }
        `;

  // Apply <head/> content
  head.innerHTML = headContent;

  // Apply <body/> content
  // In case if preview product is Newsletter Forms, we need to apply only script to <body/>
  if (previewProduct === PreviewProduct.NEWSLETTER_FORMS) {
    const body = document.getElementsByTagName("body")[0];

    const script = document.createElement("script");

    script.setAttribute("type", "text/javascript");

    script.innerHTML = `window._upEventBus = {publish:()=>{}};${resultJs}`;

    body.innerHTML = "";

    body.append(script);
  } else {
    // In case if preview product is Activation Popup, we need to apply only html to <body/>
    document.body.innerHTML = resultHtml || "";

    // Remove all links from <body/> to prevent redirection
    const anchors = document.body.getElementsByTagName("a");
    for (let i = 0; i < anchors.length; i++) {
      anchors[i].removeAttribute("href");
      anchors[i].removeAttribute("target");
    }
  }
};
