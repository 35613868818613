import { QueryConfigProvider } from "shared/components";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { ContentWrapper } from "../TriggerSettingsFormStyles";
import { ComeBackMailingContentHeadingForm } from "../ComeBackMailingContentHeadingForm";
import { ComeBackMailingVoucherSettingsList } from "../ComeBackMailingVoucherSettingsList";
import { ComeBackMailingTextSettingsList } from "../ComeBackMailingTextSettingsList";

export const ComeBackMailingSettingsContent = () => {
  return (
    <ContentWrapper>
      <QueryConfigProvider queryName="comeBackMailingConfig">
        <ComeBackMailingContentHeadingForm />
      </QueryConfigProvider>
      <QueryConfigProvider queryName="comeBackMailingVouchers" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ComeBackMailingVoucherSettingsList />
      </QueryConfigProvider>
      <QueryConfigProvider queryName="comeBackMailingTexts" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ComeBackMailingTextSettingsList />
      </QueryConfigProvider>
    </ContentWrapper>
  );
};
