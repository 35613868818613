import { LoadingIndicator, PreviewComponent } from "shared/components";
import { PreviewProduct, DeviceType } from "shared/types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PreviewComponentWrapper, Wrapper, LoadingWrapper } from "./Preview.styles";
import { PreviewProps } from "./Preview.props";
import { PreviewModeControl } from "./PreviewModeControl";
import { PreviewVariantControl } from "./PreviewVariantControl";
import { useAccountStep, usePreviewDataPrepare, usePreviewTemplates } from "../../../../hooks";
import { PreviewVariantState } from "../../../../types";
import { previewVariants } from "../../../../config";

export const Preview = ({
  designSettings,
  activationPreviewVisible,
  newsletterPreviewVisible
}: PreviewProps) => {
  const { i18n } = useTranslation();

  const {
    activationTemplates,
    optinTemplates,
    optinTranslations,
    optinLanguages,
    activationLanguages,
    optinInputs,
    optinPositions,
    loading
  } = usePreviewTemplates();

  const { data } = useAccountStep();

  const previewProductsList = useMemo(() => {
    const list = [];

    if (activationPreviewVisible) list.push(PreviewProduct.ACTIVATION_POPUP);
    if (newsletterPreviewVisible) list.push(PreviewProduct.NEWSLETTER_FORMS);

    return list;
  }, [activationPreviewVisible, newsletterPreviewVisible]);

  const [previewVariantState, setPreviewVariantState] = useState<PreviewVariantState>({
    product: previewProductsList[0],
    variant: previewVariants[previewProductsList[0]],
    language: i18n.language,
    device: DeviceType.DESKTOP
  });

  const { template, settings } = usePreviewDataPrepare(
    previewVariantState,
    activationTemplates,
    optinTemplates,
    optinTranslations,
    optinInputs,
    optinPositions
  );

  if (loading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  return (
    <Wrapper>
      <PreviewModeControl
        state={previewVariantState}
        activationLanguages={activationLanguages}
        optinLanguages={optinLanguages}
        onChange={setPreviewVariantState}
      />
      <PreviewComponentWrapper>
        <PreviewComponent
          url={data?.webUrl}
          device={previewVariantState.device}
          previewProduct={previewVariantState.product}
          template={template}
          settings={settings}
          design={designSettings}
        />
      </PreviewComponentWrapper>
      <PreviewVariantControl
        previewProductsList={previewProductsList}
        state={previewVariantState}
        onChange={setPreviewVariantState}
      />
    </Wrapper>
  );
};
