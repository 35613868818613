import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { putComeBackMailingText } from "../../api";
import {
  ComeBackMailingTextSettingsPayload,
  ComeBackMailingTextSettingsValues,
  ComeBackMailingTextInitialSettingsValues
} from "../../types";
import { mapFormToCustomConditions } from "../../utils";
import { TriggerConfig } from "api/models";

export const useUpdateComeBackMailingText = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: updateComeBackMailingText } = useMutation<
    void,
    AxiosError<{ message: string }>,
    ComeBackMailingTextSettingsPayload & { shopId: number; id: number }
  >(putComeBackMailingText);

  const executeUpdateComeBackMailingText: FormProps<
    ComeBackMailingTextSettingsValues,
    ComeBackMailingTextInitialSettingsValues
  >["onSubmit"] = useCallback(
    async (
      {
        contactPerson,
        title,
        customParameter,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        id,
        conditions
      },
      form
    ) => {
      try {
        await updateComeBackMailingText({
          customParameter: customParameter?.length ? customParameter : undefined,
          utmCampaign: utmCampaign?.length ? utmCampaign : undefined,
          utmContent: utmContent?.length ? utmContent : undefined,
          utmMedium: utmMedium?.length ? utmMedium : undefined,
          utmSource: utmSource?.length ? utmSource : undefined,
          contactPersonId: contactPerson.contactPersonId,
          shopId: selectedShopId!,
          conditions: mapFormToCustomConditions(conditions),
          id,
          title
        });

        queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);

        form.reset();

        const data = queryClient.getQueryData<TriggerConfig>([
          "comeBackMailingConfig",
          selectedShopId
        ]);

        if (data?.enabled) {
          showNotification("featuresModule.comeBackMailing.updateSuccessMessage", "success");
        } else {
          showNotification("featuresModule.comeBackMailing.notActivatedMessage", "warning");
        }
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [selectedShopId, queryName, limit, offset, showNotification]
  );

  return executeUpdateComeBackMailingText;
};
