import { Theme, useMediaQuery } from "@mui/material";
import { LoadingIndicator } from "shared/components";
import { QuestionnaireStatus } from "api/models";
import { Wrapper } from "./Stepper.styles";
import { StepperTab } from "./StepperTab";
import { useQuestionnaireStepperState } from "../../hooks";

export const Stepper = () => {
  const desktop = useMediaQuery(({ breakpoints }: Theme) => breakpoints.up("lg"));

  const { organizedSteps, selectedStep, questionnaireStatus, loading, handleChangeStep } =
    useQuestionnaireStepperState();

  const questionnaireSubmitted = questionnaireStatus === QuestionnaireStatus.SUBMITTED;

  if (!desktop) return null;

  if (loading)
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );

  return (
    <Wrapper>
      {organizedSteps.map(({ title, steps }, index) => (
        <StepperTab
          key={title}
          title={title}
          questionnaireSubmitted={questionnaireSubmitted}
          index={index + 1}
          steps={steps}
          selectedStep={selectedStep}
          onChangeStep={handleChangeStep}
        />
      ))}
    </Wrapper>
  );
};
