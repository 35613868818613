import { QuestionnaireAddOptionStepData } from "api/models";
import { ValidationErrors, FORM_ERROR } from "final-form";

export const addOptionsValidation =
  (visibleFields: (keyof QuestionnaireAddOptionStepData)[]) =>
  (fields: QuestionnaireAddOptionStepData): ValidationErrors => {
    let result: ValidationErrors = {};

    const filteredFields = Object.entries(fields).filter(([key]) =>
      visibleFields.includes(key as keyof QuestionnaireAddOptionStepData)
    );

    if (filteredFields.every(([, field]) => field === false)) {
      result = {
        ...result,
        [FORM_ERROR]: "questionnaireModule.errors.atLeastOneActivated"
      };
    }

    return result;
  };
