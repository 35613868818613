import React, { memo, FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { importedModules } from "modules-routes";
import { Routes } from "config/navigation";
import { useOpenFeatureFlag } from "../hooks";
import { PageLoading } from "../components/utils/PageLoading";

export const withFeatureNavigation = <P extends object>(
  Component: React.ComponentType<P>,
  requiredPath: Routes
): FC<P> => {
  const WithFeatureNavigationComponent: FC<P> = (props) => {
    // As for now consider only autogenerated modules
    // for legacy routes this functionality won't work
    const config = importedModules
      .map((module) => module.getPageConfig())
      .find(({ path }) => path === requiredPath);

    if (!config || !config.featureFlag) {
      return <Component {...(props as P)} />;
    }

    const { loading, value } = useOpenFeatureFlag(config.featureFlag);

    const { pathname } = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
      if (value && pathname !== requiredPath) {
        navigate(requiredPath);
      }
    }, [value, pathname]);

    if (loading) {
      return <PageLoading />;
    }

    return <Component {...(props as P)} />;
  };

  return memo(WithFeatureNavigationComponent);
};
