import { useMemo, memo } from "react";
import { useOpenFeatureFlag, useShopListData } from "../hooks";
import { PageLoading } from "../components/utils/PageLoading";
import { Permission } from "../types";
import NotFound from "legacy/containers/NotFoundPage";

export const withFeatureFlag = <P extends object>(
  Component: React.ComponentType<P>,
  requiredFeature: string,
  requiredPermission: Permission
): React.FC<P> => {
  const WithFeatureFlagComponent: React.FC<P> = (props) => {
    const { loading, value } = useOpenFeatureFlag(requiredFeature);

    // TODO: https://uptain.atlassian.net/browse/UP-3910
    // move to a separate user data fetch procedure and cover page
    // with loading state until it's done to avoid loading logic separation
    // additionally check for required permissions
    // to prevent shop data loading errors for publicly available pages
    const shopList = requiredPermission && useShopListData();

    const pageDisabled = useMemo(() => value !== true, [value]);

    if (loading || (shopList && shopList.loading)) {
      return <PageLoading />;
    }

    if (pageDisabled) {
      return <NotFound />;
    }

    return <Component {...(props as P)} />;
  };

  return memo(WithFeatureFlagComponent);
};
