import { PREVIEW_USER_AGENTS, PREVIEW_DIMENSIONS } from "config/constants";
import { useQuery } from "@tanstack/react-query";
import { DeviceType } from "../../../../types";
import { useSelectedShop } from "../../../../hooks";
import { getWebsiteImage } from "../../api";

const queryName = "websiteImage";

export const useWebsiteImage = (device: DeviceType, url?: string) => {
  const { selectedShop } = useSelectedShop();

  const userAgent = PREVIEW_USER_AGENTS[device];

  const dimensions = PREVIEW_DIMENSIONS[device];

  const { data, isLoading } = useQuery(
    [queryName, url, device],
    async () =>
      await getWebsiteImage({
        ...dimensions,
        userAgent,
        url: url!,
        mongoShopId: selectedShop!
      }),
    {
      enabled: !!url && !!selectedShop,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity
    }
  );

  const result = data?.data;

  return { image: result, loading: isLoading };
};
