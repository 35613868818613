import {
  ActivationTemplate,
  NewsletterFormsTemplate,
  NewsletterFormsInputs,
  NewsletterFormsTranslation
} from "api/models";
import { PREVIEW_MOCK_DATA } from "config/constants";
import { useMemo } from "react";
import { PreviewProduct, PreviewSettings, TemplateData } from "shared/types";
import { NewsletterFormsTemplatePositions, PreviewVariantState } from "../../types";
import {
  prepareOptinSettings,
  selectActivationTemplate,
  selectOptinTemplate
} from "./usePreviewDataPrepare.helpers";

// Function for rendering payload preparation
export const usePreviewDataPrepare = (
  { product, language, variant, device }: PreviewVariantState,
  activationTemplates: ActivationTemplate[],
  optinTemplates: NewsletterFormsTemplate[],
  optinTranslations: NewsletterFormsTranslation[],
  optinInputs: NewsletterFormsInputs[],
  optinPositions: NewsletterFormsTemplatePositions
): { template: TemplateData; settings: PreviewSettings } => {
  // Select mock data based on variant
  const mockSettingsData = useMemo(() => PREVIEW_MOCK_DATA[variant], [variant]);

  // Select template based on product
  const template = useMemo(() => {
    switch (product) {
      case PreviewProduct.ACTIVATION_POPUP:
        return selectActivationTemplate(activationTemplates, language, variant, device);
      case PreviewProduct.NEWSLETTER_FORMS:
        return selectOptinTemplate(optinTemplates, optinPositions, variant, device);
    }
  }, [product, activationTemplates, optinTemplates, optinPositions, language, variant, device]);

  // Get default first optin inputs value
  const optinInputsData = useMemo(() => {
    return optinInputs[0] ?? {};
  }, [optinInputs]);

  // Select translation based on variant and language
  const optinTranslation = useMemo(
    () =>
      optinTranslations.find(({ meta: { contentType, language: translationLanguage } }) => {
        return contentType === variant && language === translationLanguage;
      }),
    [optinTranslations, variant, language]
  );

  // Prepare settings based on product
  const settings = useMemo(() => {
    switch (product) {
      case PreviewProduct.ACTIVATION_POPUP:
        return mockSettingsData;
      case PreviewProduct.NEWSLETTER_FORMS:
        return prepareOptinSettings(mockSettingsData, optinTranslation, optinInputsData);
    }
  }, [mockSettingsData, optinTranslation, optinInputsData, product]);

  return { template, settings };
};
