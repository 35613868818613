import module from "module";
import { Routes } from "config/navigation";
import { PageWrapper, PublicFooter } from "shared/components";
import { Maintenance } from "../components";

const MaintenancePage = () => {
  return (
    <PageWrapper>
      <Maintenance />
      <PublicFooter />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.MAINTENANCE,
  featureFlag: "maintenance_screen_enabled"
});

export default MaintenancePage;
