import { apiClient } from "shared/utils";
import { QuestionnaireTemplateDesignStepData, ShopIdParam } from "api/models";

export const putSubmitTemplateDesign = async ({
  shopId,
  ...values
}: QuestionnaireTemplateDesignStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/template-design`, values);

  return data;
};
