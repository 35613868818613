import { useMemo } from "react";
import {
  QuestionnaireStep,
  QuestionnaireStepType,
  QuestionnaireStepsDataTypesMapping,
  MetadataType
} from "api/models";
import { useQuestionnaireData, useSelectedShop } from "shared/hooks";

export const useStepsResolver = <
  StepType extends QuestionnaireStepType,
  DataType extends QuestionnaireStepsDataTypesMapping[StepType],
  Metadata extends MetadataType<StepType>
>(
  stepName: StepType
): QuestionnaireStep<DataType, Metadata> => {
  const { selectedShopId } = useSelectedShop();

  const { data } = useQuestionnaireData(selectedShopId);

  return useMemo(
    () =>
      data?.steps.find(({ type }) => type === stepName) as QuestionnaireStep<DataType, Metadata>,
    [data]
  );
};
