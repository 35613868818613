import module from "module";
import { Routes } from "config/navigation";
import { PageWrapper, NavigationBar, PublicFooter } from "shared/components";
import { LoginForm, ContentWrapper } from "../components";

const LoginPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="public" />
      <ContentWrapper>
        <LoginForm />
        <PublicFooter />
      </ContentWrapper>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.LOGIN
});

export default LoginPage;
