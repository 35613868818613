import { apiClient } from "shared/utils";
import { QuestionnaireAbandonmentMailsVoucherSettingsStepData, ShopIdParam } from "api/models";

export const putSubmitAbandonmentMailsVoucherSettings = async ({
  shopId,
  ...values
}: QuestionnaireAbandonmentMailsVoucherSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/abandonment-mail-voucher`, values);

  return data;
};
