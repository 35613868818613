import { apiClient } from "../utils";
import { GetNewsletterFormsInputsResponse } from "api/models";

export const getNewsletterFormsInputs = async (mongoShopId: string) => {
  const { data } = await apiClient.get<GetNewsletterFormsInputsResponse>(
    `/shop/${mongoShopId}/settings/common/optinInputs`
  );

  return data;
};
