import {
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData,
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepMetadata
} from "api/models";
import {
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormMetadataState,
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues
} from "../../types";

export const parseAbandonmentMailsWaitingTimeMinMinutesFormMetadata = (
  metadata: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepMetadata | undefined
): QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormMetadataState => {
  const fields = metadata?.fields;

  const abandonmentMailsWaitingTimeMinMinutesEnabled =
    !fields?.abandonmentMailsWaitingTimeMinMinutes?.disabled;

  const browseAbandonmentWaitingTimeMinHoursEnabled =
    !fields?.browseAbandonmentWaitingTimeMinHours?.disabled;

  const comeBackMailingWaitingTimeMinHoursEnabled =
    !fields?.comeBackMailingWaitingTimeMinHours?.disabled;

  return {
    abandonmentMailsWaitingTimeMinMinutesEnabled,
    browseAbandonmentWaitingTimeMinHoursEnabled,
    comeBackMailingWaitingTimeMinHoursEnabled
  };
};

export const abandonmentMailsWaitingTimeMinMinutesFormMapper = (
  {
    abandonmentMailsWaitingTimeMinMinutes,
    browseAbandonmentWaitingTimeMinHours,
    comeBackMailingWaitingTimeMinHours
  }: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormValues,
  {
    abandonmentMailsWaitingTimeMinMinutesEnabled,
    browseAbandonmentWaitingTimeMinHoursEnabled,
    comeBackMailingWaitingTimeMinHoursEnabled
  }: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesFormMetadataState
): QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData => {
  return {
    abandonmentMailsWaitingTimeMinMinutes: abandonmentMailsWaitingTimeMinMinutesEnabled
      ? abandonmentMailsWaitingTimeMinMinutes
      : undefined,
    browseAbandonmentWaitingTimeMinHours: browseAbandonmentWaitingTimeMinHoursEnabled
      ? browseAbandonmentWaitingTimeMinHours
      : undefined,
    comeBackMailingWaitingTimeMinHours: comeBackMailingWaitingTimeMinHoursEnabled
      ? comeBackMailingWaitingTimeMinHours
      : undefined
  };
};
