import { PropsWithChildren } from "react";
import { GlobalStyles } from "config/theme/GlobalStyles";
import { styled, Box as MuiBox } from "@mui/material";
import { PageWrapperProps } from "./PageWrapper.props";
import background_lines_small from "assets/images/background_lines/small.png";
import background_lines_large from "assets/images/background_lines/large.png";

export const PageWrapper = styled(({ children, ...props }: PropsWithChildren<PageWrapperProps>) => {
  return (
    <>
      {/* Placed global styles at page wrapper for now to change background color only at new pages */}
      <GlobalStyles />
      <MuiBox {...props}>{children}</MuiBox>
    </>
  );
})(
  ({
    theme: {
      palette: {
        custom: { background }
      },
      breakpoints
    }
  }) => {
    return `
      background-color: ${background};
      background-image: url(${background_lines_small});
      background-size: cover;
      background-repeat: no-repeat;
      background-clip: border-box;
      background-position: center;
      background-attachment: fixed;
      width: 100%;
      min-height: 100vh;

      ${[breakpoints.up("lg")]} {
        background-image: url(${background_lines_large});
      }
    `;
  }
);
