import { styled, Slider as MuiSlider } from "@mui/material";

export const Slider = styled(MuiSlider)(
  ({
    theme: {
      palette: { custom }
    }
  }) => `
    &.MuiSlider-root {
      color: ${custom.turquoise.main};

      & .MuiSlider-rail {
        background-color: ${custom.white.light};
        height: 6px;
      }

      & .MuiSlider-track {
        background-color: ${custom.turquoise.main};
        height: 6px;
      }

      & .MuiSlider-markLabel {
        color: ${custom.white.main};
      }

      & .MuiSlider-mark {
        background-color: ${custom.white.light};
        width: 6px;
        height: 6px;
        border-radius: 50%;

        &.MuiSlider-markActive {
          background-color: ${custom.white.main};
        }
      }
    }
  `
);
