import { QuestionnaireShopSystem } from "api/models";
import JTL_4 from "assets/images/shop_system_icons/jtl_4.png";
import JTL_5 from "assets/images/shop_system_icons/jtl_5.png";
import MAGENTO_1 from "assets/images/shop_system_icons/magento_1.png";
import MAGENTO_2 from "assets/images/shop_system_icons/magento_2.png";
import OXID from "assets/images/shop_system_icons/oxid.png";
import PLENTYMARKETS from "assets/images/shop_system_icons/plentymarkets.png";
import POWERGAP from "assets/images/shop_system_icons/powergap.png";
import PRESTASHOP from "assets/images/shop_system_icons/prestashop.png";
import SHOPIFY from "assets/images/shop_system_icons/shopify.png";
import SHOPWARE_5 from "assets/images/shop_system_icons/shopware_5.png";
import SHOPWARE_6 from "assets/images/shop_system_icons/shopware_6.png";
import VERSA_COMMERCE from "assets/images/shop_system_icons/versa_commerce.png";
import WOOCOMMERCE from "assets/images/shop_system_icons/woocommerce.png";
import XONIC from "assets/images/shop_system_icons/xonic.png";
import GOOGLE_TAG_MANAGER from "assets/images/shop_system_icons/google_tag_manager.png";
import JAVA_SCRIPT from "assets/images/shop_system_icons/javascript.png";

export const questionnaireShopSystemIconsConfig: Record<QuestionnaireShopSystem, string> = {
  [QuestionnaireShopSystem.JTL_4]: JTL_4,
  [QuestionnaireShopSystem.JTL_5]: JTL_5,
  [QuestionnaireShopSystem.MAGENTO_1]: MAGENTO_1,
  [QuestionnaireShopSystem.MAGENTO_2]: MAGENTO_2,
  [QuestionnaireShopSystem.OXID]: OXID,
  [QuestionnaireShopSystem.PLENTYMARKETS]: PLENTYMARKETS,
  [QuestionnaireShopSystem.POWERGAP]: POWERGAP,
  [QuestionnaireShopSystem.PRESTASHOP]: PRESTASHOP,
  [QuestionnaireShopSystem.SHOPWARE_5]: SHOPWARE_5,
  [QuestionnaireShopSystem.SHOPWARE_6]: SHOPWARE_6,
  [QuestionnaireShopSystem.SHOPIFY]: SHOPIFY,
  [QuestionnaireShopSystem.VERSA_COMMERCE]: VERSA_COMMERCE,
  [QuestionnaireShopSystem.WOOCOMMERCE]: WOOCOMMERCE,
  [QuestionnaireShopSystem.XONIC]: XONIC,
  [QuestionnaireShopSystem.GOOGLE_TAG_MANAGER]: GOOGLE_TAG_MANAGER,
  [QuestionnaireShopSystem.JAVA_SCRIPT]: JAVA_SCRIPT
};
