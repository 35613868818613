import { ContactPerson } from "api/models";
import { TextButton } from "shared/components";
import { useCallback, useEffect, useState } from "react";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Collapse } from "./CreateContactPersonForm.styles";
import { ContactPersonFormBase } from "../ContactPersonFormBase";
import { useCreateContactPerson } from "../../hooks";

const FORM_INITIAL_STATE: ContactPerson = {
  contactPersonId: 0,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  title: ""
};

export const CreateContactPersonForm = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation();

  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    if (disabled) {
      setFormVisible(false);
    }
  }, [disabled]);

  const handleCreateToggle = useCallback(() => {
    setFormVisible((prev) => !prev);
  }, []);

  const submitMethod = useCreateContactPerson();

  const handleFormSubmit = useCallback(
    async (values: ContactPerson, form: FormApi<ContactPerson, ContactPerson>) => {
      const res = await submitMethod(values, form);
      if (res) return res;
      setFormVisible(false);
    },
    [submitMethod]
  );

  // Logic to set title field value based on firstName and lastName fields
  // in case user didn't provide title value yet
  const handleValuesChange = useCallback(
    (values: ContactPerson, form: FormApi<ContactPerson, Partial<ContactPerson>>) => {
      if (!form.getFieldState("title")?.visited && (values.firstName || values.lastName)) {
        form.change("title", `${values.firstName} ${values.lastName}`);
      }
    },
    []
  );

  return (
    <>
      <TextButton
        iconPosition="start"
        disabled={disabled}
        icon={
          <AddIcon
            sx={{
              transform: `rotate(${formVisible ? "225" : "0"}deg)`,
              transition: "transform 0.3s ease"
            }}
          />
        }
        variant="tertiary"
        onClick={handleCreateToggle}
      >
        {t(`settingsModule.contactPersons.form.${formVisible ? "closeForm" : "addMoreButton"}`)}
      </TextButton>
      <Collapse in={formVisible} unmountOnExit>
        <ContactPersonFormBase
          initialState={FORM_INITIAL_STATE}
          formOpened={true}
          onSubmit={handleFormSubmit}
          onValuesChange={handleValuesChange}
        />
      </Collapse>
    </>
  );
};
