import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PaymentSettings } from "api/models";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { FormProps } from "react-final-form";
import { putComeBackMailingPayment } from "../../api";

export const useUpdateComeBackMailingPayment = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: updatePaymentSettings } = useMutation<
    void,
    AxiosError<{ message: string }>,
    PaymentSettings & { shopId: number }
  >(putComeBackMailingPayment);

  const executeUpdateComeBackMailingSettings: FormProps<
    PaymentSettings,
    PaymentSettings
  >["onSubmit"] = useCallback(
    async (values, form) => {
      try {
        await updatePaymentSettings({
          ...values,
          shopId: selectedShopId!
        });

        queryClient.invalidateQueries([queryName, selectedShopId]);

        form.reset();

        showNotification("settingsModule.triggerMailsPayment.updateSuccessMessage", "success");
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [queryName, selectedShopId, showNotification]
  );

  return executeUpdateComeBackMailingSettings;
};
