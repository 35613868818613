import { apiClient } from "shared/utils";
import { QuestionnaireCustomerSupportStepData, ShopIdParam } from "api/models";

export const putSubmitCustomerSupport = async ({
  shopId,
  ...values
}: QuestionnaireCustomerSupportStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/customer-support`, values);

  return data;
};
