import { apiClient } from "shared/utils";
import { QuestionnaireActivationPopupsVoucherSettingsStepData, ShopIdParam } from "api/models";

export const putSubmitActivationPopupsVoucherSettings = async ({
  shopId,
  ...values
}: QuestionnaireActivationPopupsVoucherSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/activation-popup-voucher`, values);

  return data;
};
