import { useQuestionnaireSubmitMutation } from "./../useQuestionnaireSubmitMutation/useQuestionnaireSubmitMutation";
import { useCallback, useMemo } from "react";
import {
  QuestionnaireNewsletterFormsVoucherSettingsStepData,
  QuestionnaireStepType,
  LegacyVoucherTypes,
  LegacyCurrencies
} from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { putSubmitNewsletterFormsVoucherSettings } from "../../api";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import {
  QuestionnaireNewsletterFormsVoucherSettingsFormValues,
  VoucherListControlMode
} from "../../types";
import { mapLabelValuePairToKeyTitlePair, parseVoucherSettingsFormStepMetadata } from "../../utils";
import { newsletterFormsVoucherSettingsFormMapper } from "./useNewsletterFormsVoucherSettingsStep.helpers";

export const useNewsletterFormsVoucherSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.NEWSLETTER_FORMS_VOUCHER_SETTINGS);

  const data: QuestionnaireNewsletterFormsVoucherSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      voucherValueType: stepData?.data?.voucherValueType || LegacyCurrencies.PERCENTAGE,
      voucherKind: stepData?.data?.voucherKind || LegacyVoucherTypes.CODE,
      excludedCustomerGroups: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedCustomerGroups
      ),
      excludedPagesToShowVoucher: mapLabelValuePairToKeyTitlePair(
        stepData?.data?.excludedPagesToShowVoucher
      ),
      voucherListControlMode:
        stepData?.data?.listName && stepData?.data?.voucherKind === LegacyVoucherTypes.LIST
          ? VoucherListControlMode.SELECT
          : VoucherListControlMode.CREATE
    }),
    [stepData?.data]
  );

  const metadata = useMemo(
    () => parseVoucherSettingsFormStepMetadata(stepData?.metadata),
    [stepData?.metadata]
  );

  const submitNewsletterFormsVoucherSettings =
    useQuestionnaireSubmitMutation<QuestionnaireNewsletterFormsVoucherSettingsStepData>(
      putSubmitNewsletterFormsVoucherSettings
    );

  const executeSubmitNewsletterFormsVoucherSettings: FormProps<
    QuestionnaireNewsletterFormsVoucherSettingsFormValues,
    QuestionnaireNewsletterFormsVoucherSettingsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const newsletterFormsVoucherSettingsSubmitData = newsletterFormsVoucherSettingsFormMapper(
          formValues,
          metadata
        );

        await submitNewsletterFormsVoucherSettings(newsletterFormsVoucherSettingsSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitNewsletterFormsVoucherSettings, metadata]
  );

  return {
    data,
    metadata,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitNewsletterFormsVoucherSettings
  };
};
