import { useTheme } from "@mui/material";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { VoucherList } from "api/models";
import { useTranslation } from "react-i18next";
import { TextInput } from "../TextInput";
import { useVoucherListsList } from "../../../hooks";
import { Autocomplete, Chevron, makePaperStyles } from "./VoucherListSelect.styles";
import { VoucherListSelectProps } from "./VoucherListSelect.props";

export const VoucherListSelect = ({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  errorMessage,
  marginBottom,
  required
}: VoucherListSelectProps) => {
  const theme = useTheme();

  const [opened, setOpened] = useState(false);

  const { data, loading, refetch } = useVoucherListsList();

  const { t } = useTranslation();

  const handleOpen = useCallback(() => {
    setOpened(true);
    refetch();
    if (onFocus) onFocus();
  }, [refetch, onFocus]);

  const handleClose = useCallback(() => {
    setOpened(false);
    if (onBlur) onBlur();
  }, [onBlur]);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: VoucherList) => {
      onChange(value.name);
    },
    [onChange]
  );

  const options = useMemo(
    () => [...data, { name: value, amount: 0, totalAmount: 0 }],
    [data, value]
  );

  return (
    <Autocomplete
      options={options}
      value={{ name: value, amount: 0, totalAmount: 0 }}
      open={opened}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      loadingText={t("featuresModule.voucherList.loading")}
      noOptionsText={t("featuresModule.voucherList.noOptions")}
      loading={loading}
      getOptionLabel={({ name }: VoucherList) => name}
      isOptionEqualToValue={(option, selected) => {
        if (!data.length) return true;
        return option.name === selected.name;
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          required={required}
          placeholder={t("featuresModule.voucherList.placeholder")}
          label={t("featuresModule.voucherList.label")}
          error={error}
          errorMessage={errorMessage}
          marginBottom={marginBottom}
        />
      )}
      popupIcon={<Chevron />}
      disableClearable
      filterSelectedOptions
      slotProps={{
        paper: {
          sx: makePaperStyles(theme)
        }
      }}
    />
  );
};
