import { LegacyCurrencies, Currencies } from "api/models";
import { KeyTitlePair } from "shared/types";

export const LEGACY_CURRENCIES: KeyTitlePair[] = [
  { key: LegacyCurrencies.EUR, title: "EUR" },
  { key: LegacyCurrencies.USD, title: "USD" },
  { key: LegacyCurrencies.GBP, title: "GBP" },
  { key: LegacyCurrencies.CHF, title: "CHF" },
  { key: LegacyCurrencies.SEK, title: "SEK" },
  { key: LegacyCurrencies.PLN, title: "PLN" },
  { key: LegacyCurrencies.PERCENTAGE, title: "%" }
];

export const CURRENCIES: KeyTitlePair[] = [
  { key: Currencies.EUR, title: "EUR" },
  { key: Currencies.USD, title: "USD" },
  { key: Currencies.GBP, title: "GBP" },
  { key: Currencies.CHF, title: "CHF" },
  { key: Currencies.SEK, title: "SEK" },
  { key: Currencies.PLN, title: "PLN" },
  { key: Currencies.PERCENTAGE, title: "%" }
];
