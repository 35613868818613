import { LoginResponse, HeadersResponse } from "api/models";
import decode from "jwt-decode";
import { localStorage, setCookie, getCookieExpirationDate } from "shared/utils";
import { LocalStorageKey } from "shared/types";

const GOOGLE_LOGIN_EVENT_NAME = "login";

const COOKIE_NAME = "upcuid";

export const addGoogleAnalyticsEvent = (response: LoginResponse) => {
  const castedWindow = window as unknown as {
    dataLayer: {
      event: string;
      [key: string]: string;
    }[];
  };

  /**
   * Assign event with userId to dataLayer
   * @see https://developers.google.com/tag-manager/devguide
   */
  castedWindow.dataLayer = castedWindow.dataLayer || [];
  castedWindow.dataLayer.push({
    event: GOOGLE_LOGIN_EVENT_NAME,
    userId: response.data.id
  });
};

export const setUserDataToLocalStorage = (response: HeadersResponse) => {
  const headers = response?.headers;

  const { authorization } = headers;

  const decodedToken = decode<{ session: string }>(authorization);

  localStorage.setItem(LocalStorageKey.TOKEN, authorization);
  localStorage.setItem(LocalStorageKey.SESSION_ID, decodedToken.session);
};

export const setUserDataToCookies = (remember: boolean, shopId: number) => {
  setCookie(COOKIE_NAME, shopId ?? "", {
    domain: process.env.REACT_APP_COOKIE_DOMAIN ?? ".uptain.de",
    expires: getCookieExpirationDate(new Date(), remember ? { year: 1 } : { minutes: 240 })
  });
};
