import { useCallback } from "react";
import { QuestionnaireFormOfAddressStepData, QuestionnaireStepType } from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitFormOfAddress } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { formOfAddressFormMapper } from "./useFormOfAddressStep.helpers";

export const useFormOfAddressStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.FORM_OF_ADDRESS);

  const data = stepData?.data;

  const metadata = stepData?.metadata;

  const fromPersonInactive = metadata?.fields?.contactUseFirstPerson.disabled;

  const fromTeamInactive = metadata?.fields?.contactUseTeam.disabled;

  const contactEnabled = !fromPersonInactive || !fromTeamInactive;

  const submitFormOfAddress =
    useQuestionnaireSubmitMutation<QuestionnaireFormOfAddressStepData>(putSubmitFormOfAddress);

  const executeSubmitFormOfAddress: FormProps<
    QuestionnaireFormOfAddressStepData,
    QuestionnaireFormOfAddressStepData
  >["onSubmit"] = useCallback(
    async (formValues) => {
      const formOfAddressSubmitData = formOfAddressFormMapper(formValues, metadata);

      try {
        await submitFormOfAddress(formOfAddressSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitFormOfAddress, contactEnabled]
  );

  return {
    data,
    hasPrev: !!prevStep,
    contactEnabled,
    handlePrevStep,
    executeSubmitFormOfAddress
  };
};
