import { useMutation } from "@tanstack/react-query";
import { SetPasswordResponse, SetPasswordRequest } from "api/models";
import { AxiosError } from "axios";
import { FormProps } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useEffect, useCallback } from "react";
import { postSetPassword } from "../../api";
import { SetPasswordValues } from "../../types";
import { useAuthenticateUser } from "./useSetPasswordMutation.helpers";

const NAVIGATION_DELAY = 7000;

export const useSetPasswordMutation = (hash?: string) => {
  let timer: ReturnType<typeof setTimeout> | null = null;

  const authenticateMethod = useAuthenticateUser();

  const { data, mutateAsync, isSuccess } = useMutation<
    SetPasswordResponse,
    AxiosError<{ message: string }>,
    SetPasswordRequest
  >(postSetPassword);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const skipDelay = useCallback(() => {
    if (data) {
      authenticateMethod(data);
    }
  }, [authenticateMethod, data, isSuccess]);

  const execute: FormProps<SetPasswordValues, SetPasswordValues>["onSubmit"] = useCallback(
    async (values) => {
      try {
        const res = await mutateAsync({
          hash: hash ?? "",
          password: values.password
        });

        timer = setTimeout(() => {
          authenticateMethod(res);
        }, NAVIGATION_DELAY);
      } catch (error) {
        let errorTranslationKey = "global.error.serverUnavailable";

        if ((error as AxiosError<{ message: string }>).response?.status === 400) {
          errorTranslationKey = "authModule.errors.linkInvalid";
        }

        return {
          [FORM_ERROR]: errorTranslationKey
        };
      }
    },
    [timer, authenticateMethod]
  );

  return { skipDelay, execute };
};
