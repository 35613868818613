import { GetComeBackMailingTextListResponse } from "api/models";
import { apiClient } from "shared/utils";
import { ComeBackMailingTextInitialSettingsValues } from "../../types";
import { mapCustomConditionsToForm } from "../../utils";

export const getComeBackMailingTextList = async (shopId: number, limit: number, offset: number) => {
  const { data } = await apiClient.get<GetComeBackMailingTextListResponse>(
    `/${shopId}/triggers/come-back-mailing/text-settings`,
    {
      params: {
        offset,
        limit
      }
    }
  );

  return {
    ...data,
    items: data.items.map<ComeBackMailingTextInitialSettingsValues>(
      ({ comeBackMailingTriggerItemId: id, ...item }) => ({
        ...item,
        id,
        conditions: mapCustomConditionsToForm(item.conditions)
      })
    )
  };
};
