import { EmailVendor } from "api/models";
import { CustomFormVariant, UptainFormVariant, VendorFormVariant } from "../FormVariant";
import { FormRendererProps } from "./FormRenderer.props";

export const FormRenderer = ({
  vendor,
  submitErrors,
  testLoading,
  testResult,
  handleTestMailSettings
}: FormRendererProps) => {
  if (vendor === EmailVendor.Uptain) {
    return <UptainFormVariant />;
  }

  if (vendor === EmailVendor.Other) {
    return (
      <CustomFormVariant
        testLoading={testLoading}
        testResult={testResult}
        submitErrors={submitErrors}
        handleTestMailSettings={handleTestMailSettings}
      />
    );
  }

  if (vendor) {
    return (
      <VendorFormVariant
        vendor={vendor}
        testLoading={testLoading}
        testResult={testResult}
        submitErrors={submitErrors}
        handleTestMailSettings={handleTestMailSettings}
      />
    );
  }

  return null;
};
