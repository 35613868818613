import { QuestionnaireStatus } from "api/models";
import { useQuestionnaireData } from "../../../../hooks/useQuestionnaireData/useQuestionnaireData";

export const useLegacyQuestionnaireCompleted = (legacyShopId: number) => {
  const { data: questionnaireData, isLoading } = useQuestionnaireData(legacyShopId);

  const questionnaireCompleted =
    questionnaireData?.status === QuestionnaireStatus.SUBMITTED || isLoading;

  return questionnaireCompleted;
};
