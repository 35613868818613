import { apiClient } from "shared/utils";
import { ComeBackMailingTextSettings } from "api/models";
import { ComeBackMailingTextSettingsPayload } from "../../types";

export const postComeBackMailingText = async ({
  shopId,
  ...values
}: ComeBackMailingTextSettingsPayload & { shopId: number }) => {
  const { data } = await apiClient.post<ComeBackMailingTextSettings>(
    `/${shopId}/triggers/come-back-mailing/text-settings`,
    values
  );

  return data;
};
