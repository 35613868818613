import {
  QuestionnaireAbandonmentMailsVoucherSettingsStepData,
  LegacyVoucherTypes
} from "api/models";
import {
  QuestionnaireAbandonmentMailsVoucherSettingsFormValues,
  QuestionnaireVoucherSettingsFormMetadataState
} from "../../types";
import {
  prepareListNameValue,
  prepareListValuesValue,
  prepareNumberValue,
  prepareValueLabelValue
} from "../../utils";

export const abandonmentMailsVoucherSettingsFormMapper = (
  {
    voucherValue,
    voucherValueType,
    voucherKind,
    voucherListControlMode,
    listName,
    newVoucherListName,
    voucher,
    extendVoucherListValues,
    newVoucherListValues,
    listVoucherCodes,
    excludeWithOrderingValueHigherThan,
    minimumOrderValue,
    excludedCustomerGroups,
    excludedPagesToShowVoucher
  }: QuestionnaireAbandonmentMailsVoucherSettingsFormValues,
  {
    minimumOrderValueEnabled,
    excludedPagesToShowEnabled,
    excludedCustomerGroupsEnabled,
    excludeOrderingValueEnabled
  }: QuestionnaireVoucherSettingsFormMetadataState
): QuestionnaireAbandonmentMailsVoucherSettingsStepData => {
  return {
    voucherValue,
    voucherValueType,
    voucherKind,
    voucher: voucherKind === LegacyVoucherTypes.CODE ? voucher : undefined,
    listName: prepareListNameValue({
      voucherKind,
      voucherListControlMode,
      listName,
      newVoucherListName
    }),
    listVoucherCodes: prepareListValuesValue({
      voucherKind,
      voucherListControlMode,
      extendVoucherListValues,
      newVoucherListValues,
      listVoucherCodes
    }),
    excludeWithOrderingValueHigherThan: excludeOrderingValueEnabled
      ? prepareNumberValue(excludeWithOrderingValueHigherThan)
      : undefined,
    hasExcludeWithOrderingValueHigherThan: excludeOrderingValueEnabled
      ? excludeWithOrderingValueHigherThan !== undefined && excludeWithOrderingValueHigherThan > 0
      : undefined,
    minimumOrderValue: minimumOrderValueEnabled ? prepareNumberValue(minimumOrderValue) : undefined,
    hasMinimumOrderValue: minimumOrderValueEnabled
      ? minimumOrderValue !== undefined && minimumOrderValue > 0
      : undefined,
    excludedCustomerGroups: excludedCustomerGroupsEnabled
      ? prepareValueLabelValue(excludedCustomerGroups)
      : undefined,
    hasExcludedCustomerGroups: excludedCustomerGroupsEnabled
      ? excludedCustomerGroups.length > 0
      : undefined,
    excludedPagesToShowVoucher: excludedPagesToShowEnabled
      ? prepareValueLabelValue(excludedPagesToShowVoucher)
      : undefined,
    hasExcludedPagesToShowVoucher: excludedPagesToShowVoucher
      ? excludedPagesToShowVoucher.length > 0
      : undefined
  };
};
