import { apiClient } from "shared/utils";
import { ComeBackMailingVoucherSettings } from "api/models";
import { ComeBackMailingVoucherSettingsPayload } from "../../types";

export const postComeBackMailingVoucher = async ({
  shopId,
  ...values
}: ComeBackMailingVoucherSettingsPayload & { shopId: number }) => {
  const { data } = await apiClient.post<ComeBackMailingVoucherSettings>(
    `/${shopId}/triggers/come-back-mailing/voucher-settings`,
    values
  );

  return data;
};
