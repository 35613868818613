import { apiClient } from "shared/utils";
import { PaymentSettings } from "api/models";

export const putBrowseAbandonmentPayment = async ({
  shopId,
  rate,
  paymentType
}: PaymentSettings & { shopId: number }) => {
  await apiClient.put<void>(`${shopId}/triggers/browse-abandonment/payment`, {
    rate,
    paymentType
  });
};
