import module from "module";
import { Routes } from "config/navigation";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { PageWrapper, NavigationBar, QueryConfigProvider } from "shared/components";
import { Permission } from "shared/types";
import { ContactPersonList } from "../components";

const ContactPersonsPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <QueryConfigProvider queryName="contactPersonList" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ContactPersonList />
      </QueryConfigProvider>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.CONTACT_PERSONS,
  requiredPermission: Permission.USER
});

export default ContactPersonsPage;
