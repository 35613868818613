import {
  TextInput,
  ContainedButton,
  TextButton,
  Link,
  Heading,
  Subheading,
  DescriptionText
} from "shared/components";
import { Form, Field } from "react-final-form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "config/navigation";
import { supportEmail, supportSubject } from "config/constants";
import { ResetPasswordValues } from "../../types";
import { useResetPasswordMutation } from "../../hooks";
import { FormWrapper } from "../FormWrapper";
import { FormContainer } from "../FormContainer";
import { BackButtonWrapper } from "./ResetPasswordForm.styles";
import { emailValidator } from "./ResetPasswordForm.validation";
import { FORM_ERROR } from "final-form";
import { ErrorText } from "../ErrorText";
import { ScreenImage } from "../ScreenImage";

const FORM_INITIAL_STATE: ResetPasswordValues = { email: "" };

export const ResetPasswordForm = () => {
  const { t } = useTranslation();

  const { search } = useLocation();

  const navigate = useNavigate();

  const submit = useResetPasswordMutation();

  const handleBackButton = useCallback(() => {
    navigate({
      pathname: Routes.LOGIN,
      search
    });
  }, [search]);

  const renderDescription = useCallback((success: boolean) => {
    return success ? (
      <DescriptionText>{t("authModule.resetPasswordScreen.successDescription")}</DescriptionText>
    ) : (
      <DescriptionText withMargin>
        {t("authModule.resetPasswordScreen.description")}{" "}
        <Link href={`${supportEmail}?subject=${supportSubject}`}>
          {t("authModule.resetPasswordScreen.supportLink")}
        </Link>
        .
      </DescriptionText>
    );
  }, []);

  return (
    <FormWrapper>
      <Form
        initialValues={FORM_INITIAL_STATE}
        onSubmit={submit}
        render={({ submitSucceeded, dirty, submitting, handleSubmit, submitErrors }) => (
          <React.Fragment>
            {submitSucceeded && <ScreenImage variant="forgotPassword" />}
            <Heading>{t("authModule.resetPasswordScreen.heading")}</Heading>
            <Subheading>
              {t(
                `authModule.resetPasswordScreen.${
                  submitSucceeded ? "successSubheading" : "subheading"
                }`
              )}
            </Subheading>
            {renderDescription(submitSucceeded)}
            {!submitSucceeded && (
              <FormContainer>
                <Field
                  name="email"
                  validate={emailValidator}
                  render={({
                    input: { value, onBlur, onChange, onFocus },
                    meta: { error, touched }
                  }) => (
                    <TextInput
                      placeholder={t("global.placeholders.email")}
                      label={t("authModule.resetPasswordScreen.form.email")}
                      value={value}
                      onChange={onChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      error={error && touched}
                      errorMessage={t(error)}
                    />
                  )}
                />
                <ContainedButton
                  loading={submitting}
                  disabled={!dirty}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("authModule.resetPasswordScreen.form.submitButton")}
                </ContainedButton>
              </FormContainer>
            )}
            <BackButtonWrapper>
              <TextButton iconPosition="start" variant="secondary" onClick={handleBackButton}>
                {t("authModule.resetPasswordScreen.backButton")}
              </TextButton>
            </BackButtonWrapper>
            {submitErrors && <ErrorText>{t(submitErrors[FORM_ERROR])}</ErrorText>}
          </React.Fragment>
        )}
      />
    </FormWrapper>
  );
};
