import { apiClient } from "shared/utils";
import {
  QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData,
  ShopIdParam
} from "api/models";

export const putSubmitAbandonmentMailsWaitingTimeMinMinutes = async ({
  shopId,
  ...values
}: QuestionnaireAbandonmentMailsWaitingTimeMinMinutesStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/mail-waiting-time`, values);

  return data;
};
