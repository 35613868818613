import { Routes } from "config/navigation";
import module from "module";
import { PageWrapper, NavigationBar } from "shared/components";
import { Permission } from "shared/types";
import { Stepper, StepRenderer } from "../components";

const Questionnaire = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <Stepper />
      <StepRenderer />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.QUESTIONNAIRE,
  disabled: false,
  requiredPermission: Permission.USER
});

export default Questionnaire;
