import { ContactPerson } from "api/models";
import { apiClient } from "shared/utils";

export const postContactPerson = async ({
  email,
  firstName,
  lastName,
  phone,
  title,
  shopId
}: ContactPerson & { shopId: number }): Promise<ContactPerson> => {
  const { data } = await apiClient.post<ContactPerson>(`${shopId}/contact-persons`, {
    email,
    firstName,
    lastName,
    phone,
    title
  });

  return data;
};
