import { ContactPerson } from "api/models";
import { apiClient } from "shared/utils";

export const putContactPerson = async ({
  email,
  firstName,
  lastName,
  phone,
  shopId,
  contactPersonId,
  title
}: ContactPerson & { shopId: number }) => {
  await apiClient.put<void>(`${shopId}/contact-persons/${contactPersonId}`, {
    email,
    firstName,
    lastName,
    phone,
    title
  });
};
