import { apiClient } from "shared/utils";
import { QuestionnaireSignatureStepData, ShopIdParam } from "api/models";

export const putSubmitSignature = async ({
  shopId,
  ...values
}: QuestionnaireSignatureStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/signature`, values);

  return data;
};
