import { TextButton } from "shared/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormApi } from "final-form";
import { useTranslation } from "react-i18next";
import { Features } from "api/models";
import { FEATURES_POSSIBLE_CONDITIONS } from "config/constants";
import AddIcon from "@mui/icons-material/Add";
import { TextSettingsFormBase } from "../TextSettingsFormBase";
import {
  BrowseAbandonmentTextSettingsValues,
  BrowseAbandonmentTextInitialSettingsValues
} from "../../types";
import { CreateFormCollapse, CreateButtonWrapper } from "../TriggerSettingsFormStyles";
import { CreateBrowseAbandonmentTextFormProps } from "./CreateBrowseAbandonmentTextForm.props";
import { useCreateBrowseAbandonmentText } from "../../hooks";

const getInitialValues = (closed: boolean): BrowseAbandonmentTextSettingsValues => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      id: 0,
      contactPerson: {
        contactPersonId: 0,
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      enabled: false,
      title: t("featuresModule.defaults.textSettingsTitle"),
      utmSource: "",
      utmMedium: "",
      utmCampaign: "",
      utmContent: "",
      customParameter: ""
    }),
    [closed]
  );
};

export const CreateBrowseAbandonmentTextForm = ({
  disabled
}: CreateBrowseAbandonmentTextFormProps) => {
  const { t } = useTranslation();

  const [formVisible, setFormVisible] = useState(false);

  const [formClosed, setFormClosed] = useState(true);

  const FORM_INITIAL_STATE = getInitialValues(formClosed);

  const submitMethod = useCreateBrowseAbandonmentText();

  useEffect(() => {
    if (disabled) {
      setFormVisible(false);
    }
  }, [disabled]);

  const handleCreateToggle = useCallback(() => {
    setFormVisible((prev) => !prev);
    setFormClosed(false);
  }, []);

  const handleFormSubmit = useCallback(
    async (
      values: BrowseAbandonmentTextSettingsValues,
      form: FormApi<BrowseAbandonmentTextSettingsValues, BrowseAbandonmentTextInitialSettingsValues>
    ) => {
      const res = await submitMethod(values, form);
      if (res) return res;
      setFormVisible(false);
    },
    [submitMethod]
  );

  const handleCloseTransitionFinished = useCallback(() => {
    setFormClosed(true);
  }, []);

  return (
    <>
      <CreateButtonWrapper>
        <TextButton
          iconPosition="start"
          disabled={disabled}
          icon={
            <AddIcon
              sx={{
                transform: `rotate(${formVisible ? "225" : "0"}deg)`,
                transition: "transform 0.3s ease"
              }}
            />
          }
          variant="tertiary"
          onClick={handleCreateToggle}
        >
          {t(
            formVisible
              ? "featuresModule.closeForm"
              : "featuresModule.browseAbandonment.form.textSettings.addMoreButton"
          )}
        </TextButton>
      </CreateButtonWrapper>
      <CreateFormCollapse in={formVisible} unmountOnExit onExited={handleCloseTransitionFinished}>
        <TextSettingsFormBase
          translationKey="browseAbandonment"
          conditionFeatureName="browse_abandonment_conditions_enabled"
          possibleConditions={FEATURES_POSSIBLE_CONDITIONS[Features.BROWSE_ABANDONMENT]}
          initialState={FORM_INITIAL_STATE}
          formOpened={true}
          onSubmit={handleFormSubmit}
        />
      </CreateFormCollapse>
    </>
  );
};
