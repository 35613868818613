import { useQueryClient, useMutation } from "@tanstack/react-query";
import { BrowseAbandonmentTextSettings, TriggerConfig } from "api/models";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { FormProps } from "react-final-form";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postBrowseAbandonmentText } from "../../api";
import {
  BrowseAbandonmentTextSettingsPayload,
  BrowseAbandonmentTextSettingsValues,
  BrowseAbandonmentTextInitialSettingsValues
} from "../../types";
import { mapFormToCustomConditions } from "../../utils";

export const useCreateBrowseAbandonmentText = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: createBrowseAbandonmentText } = useMutation<
    BrowseAbandonmentTextSettings,
    AxiosError<{ message: string }>,
    BrowseAbandonmentTextSettingsPayload & { shopId: number }
  >(postBrowseAbandonmentText);

  const executeCreateBrowseAbandonmentText: FormProps<
    BrowseAbandonmentTextSettingsValues,
    BrowseAbandonmentTextInitialSettingsValues
  >["onSubmit"] = useCallback(
    async ({
      contactPerson,
      title,
      customParameter,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      conditions
    }) => {
      try {
        await createBrowseAbandonmentText({
          customParameter: customParameter?.length ? customParameter : undefined,
          utmCampaign: utmCampaign?.length ? utmCampaign : undefined,
          utmContent: utmContent?.length ? utmContent : undefined,
          utmMedium: utmMedium?.length ? utmMedium : undefined,
          utmSource: utmSource?.length ? utmSource : undefined,
          contactPersonId: contactPerson.contactPersonId,
          shopId: selectedShopId!,
          conditions: mapFormToCustomConditions(conditions),
          title
        });

        queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);
        queryClient.invalidateQueries(["browseAbandonmentCanEnable", selectedShopId]);

        const data = queryClient.getQueryData<TriggerConfig>([
          "browseAbandonmentConfig",
          selectedShopId
        ]);

        if (data?.enabled) {
          showNotification("featuresModule.browseAbandonment.createSuccessMessage", "success");
        } else {
          showNotification("featuresModule.browseAbandonment.notActivatedMessage", "warning");
        }
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [selectedShopId, queryName, limit, offset, showNotification]
  );

  return executeCreateBrowseAbandonmentText;
};
