import { Permission } from "shared/types/permission.enum";
import { Routes } from "./routes.enum";
import { NavigationBarRouteConfig } from "./navigation.types";

export const navigationBarConfig: NavigationBarRouteConfig[] = [
  {
    path: Routes.DASHBOARD,
    translationKey: "navigation.routes.dashboard"
  },
  {
    translationKey: "navigation.routes.features.name",
    children: [
      {
        path: Routes.ACTIVATION_POPUPS,
        translationKey: "navigation.routes.features.activation",
        includeShopId: true
      },
      {
        path: Routes.ABANDONMENT_MAILS,
        translationKey: "navigation.routes.features.mails",
        includeShopId: true
      },
      {
        path: Routes.NEWSLETTER_FORMS,
        translationKey: "navigation.routes.features.optin",
        includeShopId: true
      },
      {
        path: Routes.BROWSE_ABANDONMENT,
        translationKey: "navigation.routes.features.browseAbandonment",
        includeShopId: true
      },
      {
        path: Routes.COME_BACK_MAILING,
        translationKey: "navigation.routes.features.comeBackMailing",
        includeShopId: true
      }
    ]
  },
  {
    path: Routes.INTEGRATIONS,
    translationKey: "navigation.routes.integrations",
    includeShopId: true
  },
  {
    path: Routes.ALGORITHM,
    translationKey: "navigation.routes.algorithm",
    includeShopId: true
  },
  {
    translationKey: "navigation.routes.settings.name",
    requiredPermission: Permission.SALES,
    children: [
      {
        path: Routes.SHOP_SETTINGS,
        translationKey: "navigation.routes.settings.shop",
        includeShopId: true
      },
      {
        path: Routes.TRACKING_SETTINGS,
        translationKey: "navigation.routes.settings.tracking",
        includeShopId: true
      },
      {
        path: Routes.TRIGGER_MAILS_PAYMENT_SETTINGS,
        translationKey: "navigation.routes.settings.payment",
        includeShopId: true
      }
    ]
  },
  {
    translationKey: "navigation.routes.admin.name",
    requiredPermission: Permission.SALES,
    children: [
      {
        path: Routes.REPORTED_REGAINS,
        translationKey: "navigation.routes.admin.reportedRegains"
      },
      {
        path: Routes.REPORTED_SUBSCRIPTION,
        translationKey: "navigation.routes.admin.reportedSubscriptions"
      },
      {
        path: Routes.SHOP_ADMINISTRATION,
        translationKey: "navigation.routes.admin.shopAdministration"
      },
      {
        path: Routes.USER_ADMINISTRATION,
        translationKey: "navigation.routes.admin.userAdministration"
      },
      {
        path: Routes.FEATURE_TOGGLES,
        translationKey: "navigation.routes.admin.featureToggles",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.ACTIVATION_DESIGNER,
        translationKey: "navigation.routes.admin.activationDesigner",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.ACTIVATION_MANAGER,
        translationKey: "navigation.routes.admin.activationManager",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.TEMPLATE_TEXT_DESIGNER,
        translationKey: "navigation.routes.admin.templateTextDesigner",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.TEMPLATE_EDITOR,
        translationKey: "navigation.routes.admin.templateEditor",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.MAIL_TEMPLATES,
        translationKey: "navigation.routes.admin.mailTemplates",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.AB_TEST,
        translationKey: "navigation.routes.admin.aBTests",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.PROVISION_DASHBOARD,
        translationKey: "navigation.routes.admin.provisionDashboard"
      },
      {
        path: Routes.FUZZY_EDITOR,
        translationKey: "navigation.routes.admin.fuzzyEditor",
        legacy: true,
        requiredPermission: Permission.ADMIN
      },
      {
        path: Routes.TEST_PRICING_FORM,
        translationKey: "navigation.routes.admin.testPricingForm",
        legacy: true,
        requiredPermission: Permission.ADMIN
      }
    ]
  },
  {
    translationKey: "navigation.routes.analysis.name",
    requiredPermission: Permission.SALES,
    children: [
      {
        path: Routes.MAIL_CANCELLATION_DROPS,
        translationKey: "navigation.routes.analysis.mailCancellationDrops"
      },
      {
        path: Routes.ACTIVATION_CANCELLATION_DROPS,
        translationKey: "navigation.routes.analysis.activationCancellationDrops"
      },
      {
        path: Routes.MAIL_REGAIN_DROPS,
        translationKey: "navigation.routes.analysis.mailRegainDrops"
      },
      {
        path: Routes.ACTIVATION_REGAIN_DROPS,
        translationKey: "navigation.routes.analysis.activationRegainDrops"
      },
      {
        path: Routes.SESSION_DROPS,
        translationKey: "navigation.routes.analysis.sessionDrops"
      },
      {
        path: Routes.ORDER_DROPS,
        translationKey: "navigation.routes.analysis.orderDrops"
      },
      {
        path: Routes.ZERO_SCV,
        translationKey: "navigation.routes.analysis.zeroSCV"
      },
      {
        path: Routes.INVOICE_DROPS,
        translationKey: "navigation.routes.analysis.invoiceDrops"
      },
      {
        path: Routes.INVOICES,
        translationKey: "navigation.routes.analysis.invoices"
      }
    ]
  }
];
