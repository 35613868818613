import React, { useEffect, useState } from 'react';
import base64 from 'base-64';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { localStorage } from 'shared/utils';
import { LocalStorageKey } from 'shared/types';

import UpButton from '../../components/UpButton';
import UpContainer from '../../components/UpContainer/index';
import UpH3 from '../../components/UpH3/index';
import UpInput from '../../components/UpInput';
import UpModalWindow from '../../components/UpModalWindow';

import { createNewShop } from '../Admin/StoreAdministration/actions';
import { getShopList } from '../../actions/actions';
import { resetDashboard, fetchAllData } from '../LiveDashboard/actions';
import { getCookieExpirationDate, setCookie } from '../../utils/cookies';

import Footer from './Footer';

import { Row, Wrapper } from './styles';
import { selectCurrShop } from '../App/selectors';
import { selectShops } from './selectors';

const CreateNewShop = () => {
  const [createShop, setCreateShop] = useState(false);
  const [shopName, setShopName] = useState('');

  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const { selectedShop } = useSelector(selectCurrShop);
  const { shopsCount, isShopsReceived, isCreatingNewShop } =
    useSelector(selectShops);
  const isDashboardResetted = useSelector((state) =>
    state.getIn([
      'customerBackendDashboard',
      'liveDashboard',
      'resetDashboard',
    ]),
  );

  useEffect(() => {
    if (isShopsReceived) {
      if (shopsCount === 0) {
        setCreateShop(true);
      } else {
        setCookie('upcuid', selectedShop, {
          domain: '.uptain.de',
          expires: getCookieExpirationDate(new Date(), { minutes: 30 }),
        });
      }
    }
  }, [selectedShop, isShopsReceived, shopsCount]);

  useEffect(() => {
    if (createShop) {
      const token = localStorage.getItem(LocalStorageKey.TOKEN);
      const { id } = JSON.parse(base64.decode(token.split('.')[1]));

      if (isCreatingNewShop === false) {
        dispatch(resetDashboard(id));
      }

      if (isDashboardResetted) {
        dispatch(fetchAllData(id));
        dispatch(getShopList());

        setCreateShop(false);

        navigate('/algorithm');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createShop, isCreatingNewShop, isDashboardResetted]);

  const handleChange = (evt) => {
    setShopName(evt.target.value);
  };

  return (
    createShop && (
      <UpModalWindow
        title={
          <FormattedMessage
            id="containers.onboarding.createnewshop.title"
            defaultMessage="Welcome"
          />
        }
        closeable={false}
        languageSwitch
        height="auto"
      >
        <Wrapper>
          <UpContainer>
            <UpH3>
              <FormattedMessage
                id="containers.onboarding.createnewshop.header"
                defaultMessage="There is no registered shop for you yet."
              />
            </UpH3>
            <br />
            <FormattedMessage
              id="containers.onboarding.createnewshop.maintext"
              defaultMessage={`Please provide us with the name of your shop for the creation of a individual configuration. {breakingLine} Click the "Register Shop" button to proceed.`}
              values={{
                breakingLine: <br />,
              }}
            />
            <Row margin="20px 0 30px 0">
              <UpInput
                value={shopName}
                placeholder={intl.formatMessage({
                  id: 'containers.onboarding.createnewshop.placeholder',
                  defaultMessage: 'Your shop name',
                })}
                name="shopName"
                onChange={handleChange}
                margin="10px 0 0 0"
              />
              <UpButton
                disabled={!shopName}
                margin="10px 0 0 0"
                value={
                  <FormattedMessage
                    id="containers.onboarding.createnewshop.button"
                    defaultMessage="Register shop"
                  />
                }
                type="button"
                onClick={() => dispatch(createNewShop(shopName))}
                loading={isCreatingNewShop}
              />
            </Row>
            <Footer />
          </UpContainer>
        </Wrapper>
      </UpModalWindow>
    )
  );
};

export default CreateNewShop;
