import { apiClient } from "shared/utils";
import { QuestionnaireComeBackMailingVoucherSettingsStepData, ShopIdParam } from "api/models";

export const putSubmitComeBackMailingVoucherSettings = async ({
  shopId,
  ...values
}: QuestionnaireComeBackMailingVoucherSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/come-back-mailing-voucher`, values);

  return data;
};
