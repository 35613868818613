import { TextInput, Checkbox, ContainedButton, Link, Heading, Subheading } from "shared/components";
import { useInfoLinks } from "shared/hooks";
import { Form, Field } from "react-final-form";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { Routes } from "config/navigation";
import { FooterText, CheckWrapper } from "./LoginForm.styles";
import { emailValidator, passwordValidator } from "./LoginForm.validation";
import { LoginValues } from "../../types";
import { useLoginMutation, useReferrerEnhancer } from "../../hooks";
import { FormWrapper } from "../FormWrapper";
import { FormContainer } from "../FormContainer";
import { ErrorText } from "../ErrorText";

const FORM_INITIAL_STATE: LoginValues = { email: "", password: "", remember: false };

export const LoginForm = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useReferrerEnhancer();

  const navigate = useNavigate();

  const submit = useLoginMutation();

  const { register } = useInfoLinks();

  const registerLink = `${register}?${searchParams.toString()}`;

  const handleClickPasswordReset = useCallback(() => {
    navigate({
      pathname: Routes.RESET_PASSWORD,
      search: searchParams.toString()
    });
  }, [searchParams]);

  return (
    <FormWrapper>
      <Heading>{t("authModule.loginScreen.heading")}</Heading>
      <Subheading>{t("authModule.loginScreen.subheading")}</Subheading>
      <FormContainer>
        <Form
          initialValues={FORM_INITIAL_STATE}
          onSubmit={submit}
          render={({ dirty, handleSubmit, submitting, submitErrors }) => (
            <React.Fragment>
              <Field
                name="email"
                validate={emailValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched }
                }) => (
                  <TextInput
                    placeholder={t("global.placeholders.email")}
                    label={t("authModule.loginScreen.form.email")}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    error={error && touched}
                    errorMessage={t(error)}
                  />
                )}
              />
              <Field
                name="password"
                validate={passwordValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched }
                }) => (
                  <TextInput
                    password
                    placeholder="xxxxxxxxx"
                    label={t("authModule.loginScreen.form.password")}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    error={error && touched}
                    errorMessage={t(error)}
                  />
                )}
              />
              <CheckWrapper>
                <Field
                  name="remember"
                  type="checkbox"
                  render={({ input: { checked, onChange }, meta: { error } }) => (
                    <Checkbox
                      label={t("authModule.loginScreen.form.rememberMe")}
                      checked={checked}
                      onChange={onChange}
                      error={error}
                    />
                  )}
                />
                <Link underline="always" onClick={handleClickPasswordReset}>
                  {t("authModule.loginScreen.links.forgotPassword")}
                </Link>
              </CheckWrapper>
              <ContainedButton
                loading={submitting}
                disabled={!dirty}
                type="submit"
                onClick={handleSubmit}
              >
                {t("authModule.loginScreen.form.submitButton")}
              </ContainedButton>
              <FooterText>
                {t("authModule.loginScreen.footerText")}{" "}
                <Link href={registerLink} target="_blank">
                  {t("authModule.loginScreen.links.register")}
                </Link>
              </FooterText>
              {submitErrors && <ErrorText>{t(submitErrors[FORM_ERROR])}</ErrorText>}
            </React.Fragment>
          )}
        />
      </FormContainer>
    </FormWrapper>
  );
};
