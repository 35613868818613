import { RenderRequestData, RenderRequestDesign } from "api/models";
import { RefObject, useCallback } from "react";
import { DeviceType, PreviewProduct } from "shared/types";
import { postRender } from "../../api";
import { applyRenderResult } from "./usePreviewRenderer.helpers";

export const useRenderer = (iFrameReference: RefObject<HTMLIFrameElement>) => {
  const render = useCallback(
    async (
      html: string | null | undefined,
      less: string | null | undefined,
      js: string | null | undefined,
      data: RenderRequestData,
      device: DeviceType,
      design: RenderRequestDesign,
      product: PreviewProduct
    ) => {
      const response = await postRender({
        html,
        less,
        js,
        data
      });

      applyRenderResult(response, iFrameReference, design, device, product);
    },
    []
  );

  return render;
};
