/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */
import styled from 'styled-components';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import { Routes } from 'config/navigation';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faTrashAlt,
  faPlus,
  faPlusCircle,
  faPencilAlt,
  faMinus,
  faDownload,
  faQuestionCircle,
  faCheck,
  faExclamationCircle,
  faEraser,
  faTimesCircle,
  faTimes,
  faImage,
  faInfo,
  faCloudUploadAlt,
  faExclamationTriangle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faEye,
  faEyeSlash,
  faArrowsAlt,
  faInfoCircle,
  faMinusCircle,
  faExpand,
  faCompress,
  faAngleLeft,
  faAngleRight,
  faSave,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';

import DocumentLanguageUpdater from './addons/DocumentLanguageUpdater';
import ScrollToTop from './addons/ScrollToTop';
import { useFeatureToggles } from './hooks/useFeatureToggles';

import { Router } from '../../routes';
import { GlobalStyle } from '../../global-styles';
import { withFeatureNavigation } from 'shared/HoC';

library.add(
  faAngleLeft,
  faAngleRight,
  faCog,
  faTrashAlt,
  faPlus,
  faPlusCircle,
  faPencilAlt,
  faMinus,
  faDownload,
  faQuestionCircle,
  faCheck,
  faExclamationCircle,
  faTimesCircle,
  faTimes,
  faImage,
  faInfo,
  faCloudUploadAlt,
  faExclamationTriangle,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCheckSquare,
  faEye,
  faEyeSlash,
  faArrowsAlt,
  faInfoCircle,
  faMinusCircle,
  faEraser,
  faSave,
  faUndoAlt,
  faExpand,
  faCompress,
);

const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const App = () => {
  const features = useFeatureToggles();

  return (
    <DocumentLanguageUpdater>
      <FeatureToggles features={features}>
        <AppWrapper>
          <Router />
          <ScrollToTop />
          <GlobalStyle />
        </AppWrapper>
      </FeatureToggles>
    </DocumentLanguageUpdater>
  );
};

export default withFeatureNavigation(App, Routes.MAINTENANCE);
