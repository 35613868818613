import module from "module";
import { Routes } from "config/navigation";
import { PageWrapper, NavigationBar } from "shared/components";
import { Permission } from "shared/types";
import { ComeBackMailingSettingsContent } from "../components";

const ComeBackMailingPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <ComeBackMailingSettingsContent />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.COME_BACK_MAILING,
  requiredPermission: Permission.USER
});

export default ComeBackMailingPage;
