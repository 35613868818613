import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NumberConditionOperators } from "api/models";
import { Field, useForm, useFormState } from "react-final-form";
import { SelectDropdown, TextInput } from "shared/components";
import { RevenueConditionItemProps } from "./RevenueConditionItem.props";
import { BetweenValueWrapper, OperatorWrapper, ValueWrapper } from "./RevenueConditionItem.styles";
import {
  operatorValidator,
  valueValidator,
  betweenLessValidator,
  betweenMoreValidator
} from "./RevenueConditionItem.validation";
import { ConditionsObjectsFormValues, SettingsConditionsFormValues } from "../../../../types";
import { ConditionItem } from "../../ConditionItem";

export const RevenueConditionItem = ({ index }: RevenueConditionItemProps) => {
  const { t, i18n } = useTranslation();

  const operatorOptions = useMemo(
    () =>
      Object.values(NumberConditionOperators).map((key) => ({
        key,
        title: t(`featuresModule.conditions.operators.${key}`)
      })),
    [i18n.language]
  );

  const { values, submitErrors } = useFormState<SettingsConditionsFormValues>();

  const { change } = useForm<SettingsConditionsFormValues>();

  const handleNumericValueChange = useCallback(
    (onChange: (value: string) => void) => (value: string) => {
      const numericOnly = value.replace(/[^0-9.]/g, "");
      const roundedDecimals = numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

      onChange(roundedDecimals);
    },
    []
  );

  const handleNumericValueBlur = useCallback(
    (value: string, onBlur: () => void, onChange: (value: number) => void) => () => {
      if (value !== "") {
        onChange(Number(value));
      }
      onBlur();
    },
    []
  );

  const handleDelete = useCallback(() => {
    const newConditions = values.conditions?.filter(
      (_: ConditionsObjectsFormValues, i) => i !== index
    );

    change("conditions", newConditions);
  }, [change, values, index]);

  const operator = values.conditions?.[index]?.operator;

  return (
    <ConditionItem onDelete={handleDelete}>
      <Field
        name={`conditions.${index}.operator`}
        validate={operatorValidator}
        render={({
          input: { value, onChange, onBlur, onFocus },
          meta: { error, touched, modifiedSinceLastSubmit }
        }) => (
          <OperatorWrapper>
            <SelectDropdown
              label={t("featuresModule.conditions.form.revenue.label")}
              options={operatorOptions}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              marginBottom={30}
              error={
                (error ||
                  (submitErrors?.[`conditions/${index}/operator`] && !modifiedSinceLastSubmit)) &&
                touched
              }
              errorMessage={t(submitErrors?.[`conditions/${index}/operator`] ?? error)}
            />
          </OperatorWrapper>
        )}
      />
      {operator === NumberConditionOperators.BETWEEN && (
        <BetweenValueWrapper>
          <Field
            name={`conditions.${index}.betweenValue.more`}
            validate={betweenMoreValidator(index)}
            render={({
              input: { value, onChange, onBlur, onFocus },
              meta: { error, touched, modifiedSinceLastSubmit }
            }) => (
              <TextInput
                label={t("featuresModule.conditions.form.moreThen.label")}
                placeholder={t("featuresModule.conditions.form.moreThen.placeholder")}
                value={value !== undefined ? value : ""}
                fullWidth
                onChange={handleNumericValueChange(onChange)}
                onBlur={handleNumericValueBlur(value, onBlur, onChange)}
                onFocus={onFocus}
                marginBottom={32}
                error={
                  (error ||
                    (submitErrors?.[`conditions/${index}/value/more`] &&
                      !modifiedSinceLastSubmit)) &&
                  touched
                }
                errorMessage={t(submitErrors?.[`conditions/${index}/value/more`] ?? error)}
              />
            )}
          />
          <Field
            name={`conditions.${index}.betweenValue.less`}
            validate={betweenLessValidator(index)}
            render={({
              input: { value, onChange, onBlur, onFocus },
              meta: { error, touched, modifiedSinceLastSubmit }
            }) => (
              <TextInput
                label={t("featuresModule.conditions.form.lessThen.label")}
                placeholder={t("featuresModule.conditions.form.lessThen.placeholder")}
                value={value !== undefined ? value : ""}
                fullWidth
                onChange={handleNumericValueChange(onChange)}
                onBlur={handleNumericValueBlur(value, onBlur, onChange)}
                onFocus={onFocus}
                marginBottom={32}
                error={
                  (error ||
                    (submitErrors?.[`conditions/${index}/value/less`] &&
                      !modifiedSinceLastSubmit)) &&
                  touched
                }
                errorMessage={t(submitErrors?.[`conditions/${index}/value/less`] ?? error)}
              />
            )}
          />
        </BetweenValueWrapper>
      )}
      {operator !== NumberConditionOperators.BETWEEN && (
        <Field
          name={`conditions.${index}.numericValue`}
          validate={valueValidator}
          render={({
            input: { value, onChange, onBlur, onFocus },
            meta: { error, touched, modifiedSinceLastSubmit }
          }) => (
            <ValueWrapper>
              <TextInput
                label={t(`featuresModule.conditions.form.${operator}.label`)}
                placeholder={t(`featuresModule.conditions.form.${operator}.placeholder`)}
                value={value !== undefined ? value : ""}
                fullWidth
                onChange={handleNumericValueChange(onChange)}
                onBlur={handleNumericValueBlur(value, onBlur, onChange)}
                onFocus={onFocus}
                marginBottom={32}
                error={
                  (error ||
                    (submitErrors?.[`conditions/${index}/value`] && !modifiedSinceLastSubmit)) &&
                  touched
                }
                errorMessage={t(submitErrors?.[`conditions/${index}/value`] ?? error)}
              />
            </ValueWrapper>
          )}
        />
      )}
    </ConditionItem>
  );
};
