import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UpLanguageSwitch from '../UpLanguageSwitch';

import { invertTheme } from 'config/theme';

import {
  ConfigurationWrapper,
  Heading,
  H3,
  Button,
  Backdrop,
  ContentWrapper,
  HelpWidgetStyle,
} from './styles';

const UpModalWindow = ({
  title,
  close,
  children,
  languageSwitch,
  closeable,
  height,
}) => (
  <div>
    <Backdrop onClick={() => (closeable ? close() : undefined)} />
    <HelpWidgetStyle />
    <ConfigurationWrapper height={height}>
      <Heading>
        <H3>{title}</H3>
        {closeable && (
          <Button type="button" onClick={() => close()}>
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
        {languageSwitch && (
          <ThemeProvider theme={invertTheme}>
            <UpLanguageSwitch />
          </ThemeProvider>
        )}
      </Heading>
      <ContentWrapper>{children}</ContentWrapper>
    </ConfigurationWrapper>
  </div>
);

UpModalWindow.defaultProps = {
  closeable: true,
  languageSwitch: false,
};

UpModalWindow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  close: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  languageSwitch: PropTypes.bool,
  closeable: PropTypes.bool,
  height: PropTypes.string,
};

export default UpModalWindow;
