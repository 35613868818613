import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { QuestionnaireStatus, QuestionnaireStepStatus, QuestionnaireStepType } from "api/models";
import { useQuestionnaireSteps } from "../useQuestionnaireSteps";

export const useQuestionnaireStepperState = () => {
  const {
    organizedSteps,
    questionnaireSteps,
    firstPendingStep,
    enabledSteps,
    questionnaireStatus,
    loading
  } = useQuestionnaireSteps();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedStep = searchParams.get("step") as QuestionnaireStepType | null;

  const prevStep = useMemo(
    () =>
      enabledSteps[enabledSteps.findIndex((step) => step.type === selectedStep) - 1] ?? undefined,
    [enabledSteps, selectedStep]
  );

  // Effect that handles the case when user tries to navigate to a questionnaire with
  // a step parameter that is disabled or not exists
  useEffect(() => {
    if (questionnaireStatus === QuestionnaireStatus.SUBMITTED) {
      if (selectedStep) {
        searchParams.delete("step");
        setSearchParams(searchParams);
      }
      return;
    }

    if (firstPendingStep) {
      if (selectedStep) {
        const step = questionnaireSteps.find(({ type }) => type === selectedStep);

        if (!step || step.status === QuestionnaireStepStatus.DISABLED) {
          searchParams.set("step", firstPendingStep.type);
          setSearchParams(searchParams);
        }
      } else {
        searchParams.set("step", firstPendingStep.type);
        setSearchParams(searchParams);
      }
    }
  }, [firstPendingStep, selectedStep, organizedSteps, setSearchParams]);

  const handleChangeStep = useCallback(
    (step: string) => {
      searchParams.set("step", step);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handlePrevStep = useCallback(() => {
    if (!prevStep) return;
    searchParams.set("step", prevStep.type);
    setSearchParams(searchParams);
  }, [prevStep, searchParams, setSearchParams]);

  return {
    organizedSteps,
    selectedStep,
    questionnaireStatus,
    prevStep,
    loading,
    handleChangeStep,
    handlePrevStep
  };
};
