import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "shared/components";
import { PreviewProduct } from "shared/types";
import { Button, Theme, useMediaQuery } from "@mui/material";
import { ActivationType, IncentiveType } from "api/models";
import { Wrapper } from "./PreviewVariantControl.styles";
import { PreviewVariantControlProps } from "./PreviewVariantControl.props";

const activationVariants = Object.values(ActivationType);

const optinVariants = Object.values(IncentiveType);

export const PreviewVariantControl = ({
  state: { product, variant },
  previewProductsList,
  onChange
}: PreviewVariantControlProps) => {
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));

  const { t, i18n } = useTranslation();

  const previewVariants = useMemo(() => {
    switch (product) {
      case PreviewProduct.ACTIVATION_POPUP:
        return activationVariants;
      case PreviewProduct.NEWSLETTER_FORMS:
        return optinVariants;
      default:
        return activationVariants;
    }
  }, [product]);

  const handleProductChange = useCallback(
    (value: PreviewProduct) => () => {
      onChange((prev) => {
        switch (value) {
          case PreviewProduct.ACTIVATION_POPUP:
            return {
              ...prev,
              product: value,
              variant: activationVariants[0],
              language: i18n.language
            };
          case PreviewProduct.NEWSLETTER_FORMS:
            return {
              ...prev,
              product: value,
              variant: optinVariants[0],
              language: i18n.language
            };
        }
      });
    },
    [onChange]
  );

  const handleVariantChange = useCallback(
    (value: ActivationType | IncentiveType) => () => {
      onChange((prev) => ({ ...prev, variant: value }));
    },
    [onChange]
  );

  return (
    <Wrapper>
      <ButtonGroup variant="contained">
        {previewProductsList.map((key) => (
          <Button
            key={key}
            color={product === key ? "success" : "primary"}
            onClick={handleProductChange(key as PreviewProduct)}
            disabled={product === key}
          >
            {t(`questionnaireModule.steps.design.templateDesign.preview.product.${key}`)}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup orientation={desktop ? "horizontal" : "vertical"} variant="text">
        {Object.values(previewVariants).map((key) => (
          <Button
            key={key}
            color={variant === key ? "success" : "primary"}
            onClick={handleVariantChange(key as ActivationType | IncentiveType)}
            disabled={variant === key}
          >
            {t(`questionnaireModule.steps.design.templateDesign.preview.variant.${key}`)}
          </Button>
        ))}
      </ButtonGroup>
    </Wrapper>
  );
};
