import module from "module";
import { Routes } from "config/navigation";
import { DEFAULT_QUERY_LIMIT } from "config/constants";
import { PageWrapper, NavigationBar, QueryConfigProvider } from "shared/components";
import { Permission } from "shared/types";
import { ShopLinksForm } from "../components";

const ShopLinksPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <QueryConfigProvider queryName="linksConfig" initialLimit={DEFAULT_QUERY_LIMIT}>
        <ShopLinksForm />
      </QueryConfigProvider>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.SHOP_LINKS,
  requiredPermission: Permission.USER
});

export default ShopLinksPage;
