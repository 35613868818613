import { apiClient } from "shared/utils";
import { QuestionnaireNewsletterFormsVoucherSettingsStepData, ShopIdParam } from "api/models";

export const putSubmitNewsletterFormsVoucherSettings = async ({
  shopId,
  ...values
}: QuestionnaireNewsletterFormsVoucherSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/newsletter-voucher`, values);

  return data;
};
