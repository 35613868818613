import { FieldValidator } from "final-form";
import { ReachabilityDayConfig, ReachabilityDayErrors, ReachabilityDays } from "shared/types";
import moment from "moment";

const checkDayValues = (value: ReachabilityDayConfig[]) => {
  return value.reduce<ReachabilityDayErrors>((dayErrorsAcc, { start, end }, index) => {
    const startDate = moment(start, "HH:mm");
    const endDate = moment(end, "HH:mm");

    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      dayErrorsAcc[index] = [
        ...(dayErrorsAcc[index] ?? []),
        "questionnaireModule.errors.invalidTimeSlot"
      ];
    }

    return dayErrorsAcc;
  }, [] as ReachabilityDayErrors);
};

const checkTimeOverlap = (value: ReachabilityDayConfig[]) => {
  return value.reduce((acc, { start }, index) => {
    const startDate = moment(start, "HH:mm");

    const check = value.some(({ end: otherEnd }, otherIndex) => {
      if (index === otherIndex || index < otherIndex) {
        return false;
      }

      const otherEndDate = moment(otherEnd, "HH:mm");

      return startDate.isBefore(otherEndDate);
    });

    if (check) {
      if (!acc[index]) {
        acc[index] = ["questionnaireModule.errors.timeSlotOverlap"];
      } else {
        acc[index] = [...acc[index], "questionnaireModule.errors.timeSlotOverlap"];
      }
    }
    return acc;
  }, [] as ReachabilityDayErrors);
};

export const availabilityValidator: FieldValidator<
  Record<ReachabilityDays, ReachabilityDayConfig[]>
> = (value) => {
  if (Object.keys(value).every((day) => value[day as ReachabilityDays].length === 0)) {
    return { global: "questionnaireModule.errors.emptyAvailability" };
  }

  const result = Object.keys(value).reduce<
    Partial<Record<ReachabilityDays, ReachabilityDayErrors>>
  >((errorsAcc, day) => {
    const reachabilityDay = day as ReachabilityDays;

    if (value[reachabilityDay].length) {
      const overlapErrors = checkTimeOverlap(value[reachabilityDay]);

      if (overlapErrors.length) {
        errorsAcc[reachabilityDay] = overlapErrors;
      }

      const dayErrors = checkDayValues(value[reachabilityDay]);

      if (dayErrors.length) {
        errorsAcc[reachabilityDay] = dayErrors;
      }

      return errorsAcc;
    }
    return errorsAcc;
  }, {} as Partial<Record<ReachabilityDays, ReachabilityDayErrors>>);

  return Object.keys(result).length ? result : undefined;
};
