import { apiClient } from "shared/utils";
import { QuestionnaireBrowseAbandonmentVoucherSettingsStepData, ShopIdParam } from "api/models";

export const putSubmitBrowseAbandonmentVoucherSettings = async ({
  shopId,
  ...values
}: QuestionnaireBrowseAbandonmentVoucherSettingsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(
    `questionnaire/${shopId}/browse-abandonment-voucher`,
    values
  );

  return data;
};
