import { useTranslation } from "react-i18next";
import { Heading, LoadingIndicator, Subheading, Tile, DescriptionText } from "shared/components";
import { Form, Field } from "react-final-form";
import { StepWrapper, StepsContent, ErrorText } from "../../StepStyles";
import { NavigationButtons } from "../../NavigationButtons";
import { MobileStepper } from "../../Stepper";
import { useShopSystemStep } from "../../../hooks";
import { OptionsWrapper } from "./ShopSystem.styles";
import { shopSystemValidator } from "./ShopSystem.validation";
import { questionnaireShopSystemIconsConfig } from "../../../config";

export const ShopSystem = () => {
  const { t } = useTranslation();

  const { data, integrationsData, loading, hasPrev, handlePrevStep, executeSubmitShopSystem } =
    useShopSystemStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitShopSystem}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <StepsContent>
              <Heading>{t("questionnaireModule.steps.start.title")}</Heading>
              <Subheading>{t("questionnaireModule.steps.start.shopSystem.title")}</Subheading>
              <DescriptionText>
                {t("questionnaireModule.steps.start.shopSystem.description")}
              </DescriptionText>
              <Field
                name="shopSystem"
                validate={shopSystemValidator}
                render={({
                  input: { onChange, value },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <>
                    <OptionsWrapper
                      error={
                        (error || (submitErrors?.["shopSystem"] && !modifiedSinceLastSubmit)) &&
                        touched
                      }
                    >
                      {integrationsData?.map(({ id, name }) => (
                        <Tile
                          key={id}
                          label={name}
                          active={value === name}
                          icon={questionnaireShopSystemIconsConfig[name]}
                          value={name}
                          onClick={onChange}
                          width={180}
                          height={180}
                          iconParams={{
                            width: 160,
                            height: 160
                          }}
                        />
                      ))}
                    </OptionsWrapper>
                    {(error || (submitErrors?.["shopSystem"] && !modifiedSinceLastSubmit)) &&
                      touched && <ErrorText>{t(error || submitErrors?.["shopSystem"])}</ErrorText>}
                  </>
                )}
              />
              <NavigationButtons
                prevDisabled={!hasPrev}
                nextLoading={submitting}
                onNextClick={handleSubmit}
                onPrevClick={handlePrevStep}
              />
            </StepsContent>
          )}
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
