import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fromJS } from 'immutable';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { theme } from 'config/theme';
import FieldsHOC from '../FieldsHOC/index';

export const RemoveIcon = styled(FontAwesomeIcon)`
  width: 15px !important;
  height: 15px !important;
`;

export const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <RemoveIcon icon="times" />
  </components.ClearIndicator>
);

const MenuInner = styled.div`
  padding: 12px;
`;

const Menu = (props) => {
  const {
    children,
    selectProps: { limit },
    getValue,
  } = props;
  const optionSelectedLength = getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < limit ? (
        children
      ) : (
        <MenuInner>
          <FormattedMessage
            id="containers.Select.maxLimit"
            defaultMessage="Max limit achieved"
          />
        </MenuInner>
      )}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectProps: PropTypes.object,
  getValue: PropTypes.func,
};

const getBorderColor = (state) => {
  if (state.selectProps.error) return '#ac231d';
  if (state.isFocused) return '#c2e5e7';
  return '#ccc';
};

const getBackgroundColor = (state) => {
  if (state.selectProps.error) return '#f5d1d2';
  if (state.isDisabled) return '#EFEFEF26';
  return '#14171c';
};

export const customStyles = {
  input: (provided) => ({
    ...provided,
    color: '#cccccc',
  }),
  control: (provided, state) => ({
    ...provided,
    background: getBackgroundColor(state),
    boxShadow:
      state.isFocused && !state.selectProps.error
        ? '0px 0px 5px #c2e5e7'
        : null,
    border: `1px solid ${getBorderColor(state)}`,
    '&:hover': {
      borderColor: getBorderColor(state),
    },
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#14171c',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ffcdc7',
      color: '#ac231d',
    },
  }),
  indicatorsContainer: (provided, state) => {
    const display = !state.selectProps.error ? 'flex' : 'none';
    return { ...provided, display };
  },
  clearIndicator: (provided) => ({
    ...provided,
    '&:hover': {
      color: '#ac231d',
      cursor: 'pointer',
    },
  }),
  menu: (provided) => ({ ...provided, backgroundColor: '#14171c', zIndex: 10 }),
  multiValue: (provided) => ({ ...provided, background: '#dff7f6' }),
  dropdownIndicator: (provided) => ({
    ...provided,
    '&:hover': {
      color: theme.primaryColor,
      cursor: 'pointer',
    },
  }),
  option: (provided) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
};

const UpStyledReactSelect = styled(Select)`
  font-size: 12px;
  margin: ${(props) => props.margin || 'unset'};
  ${(props) => (props.flex ? `flex: ${props.flex};` : 'flex-grow: 1;')};
  height: 100% !important;
  width: ${(props) => props.width || 'auto'};
  min-width: ${(props) => props.minWidth || 0};
`;

const UpReactSelect = (props) => (
  <UpStyledReactSelect
    {...props}
    styles={customStyles}
    components={props.limit ? { ClearIndicator, Menu } : { ClearIndicator }}
    theme={(t) => getTheme(t)}
    isMulti
  />
);

export { UpReactSelect };

UpReactSelect.propTypes = {
  limit: PropTypes.number,
};

export const getTheme = (styleTheme) => ({
  ...styleTheme,
  borderRadius: 3,
  colors: {
    ...styleTheme.colors,
    primary: '#c2e5e7',
    primary25: '#1f242e',
    primary50: '#c2e5e7',
  },
});

const unwrappedMultiSelectBox = (props) => {
  const {
    input: { value, name, onChange: handleInputChange },
    customProps: { options, minWidth },
    meta: { error, submitFailed },
    placeholder,
    limit,
  } = props;

  const inputValue = value
    ? limit
      ? value.toJS().slice(0, limit)
      : value.toJS()
    : [];

  return (
    <UpReactSelect
      options={options}
      value={inputValue}
      name={name}
      onChange={(v) => {
        let val = v;
        if (v === null) val = [];
        handleInputChange(fromJS(val));
      }}
      minWidth={minWidth}
      inputDisabled
      placeholder={placeholder}
      error={error && submitFailed}
      limit={limit}
      width="100%"
    />
  );
};

const multiSelectBox = FieldsHOC(unwrappedMultiSelectBox);

export { multiSelectBox };

unwrappedMultiSelectBox.propTypes = {
  customProps: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  limit: PropTypes.number,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
};

const getValues = (options, value) =>
  options && options.filter((v) => value.includes(v.value));

const unwrappedMultiSelectBoxNonImmutable = (props) => (
  <UpReactSelect
    options={props.options}
    value={
      props.input.value === true
        ? []
        : getValues(props.options, props.input.value)
    }
    name={props.input.name}
    onChange={(value) => {
      let val = value;
      if (value === null) val = [];
      props.input.onChange(val.map((v) => v.value));
    }}
    minWidth={props.minWidth}
    inputDisabled
    placeholder={props.placeholder}
    error={props.meta.error && props.meta.submitFailed}
    width="100%"
  />
);

const multiSelectBoxNonImmutable = FieldsHOC(
  unwrappedMultiSelectBoxNonImmutable,
);

export { multiSelectBoxNonImmutable };

unwrappedMultiSelectBoxNonImmutable.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  minWidth: PropTypes.string,
};
