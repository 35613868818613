import { useTranslation, Trans } from "react-i18next";
import { Link } from "shared/components";
import { useInfoLinks } from "shared/hooks";
import maintenance from "assets/images/maintenance.png";
import { Heading, Img, Subheading, Wrapper } from "./Maintenance.styles";

export const Maintenance = () => {
  const { t } = useTranslation();

  const { contact } = useInfoLinks();

  return (
    <Wrapper>
      <Img src={maintenance} />
      <Heading>{t("internalModule.maintenance.heading")}</Heading>
      <Subheading>
        <Trans
          t={t}
          i18nKey={"internalModule.maintenance.subheading"}
          components={[<Link key="contact" href={contact} target="_blank" />]}
        />
      </Subheading>
    </Wrapper>
  );
};
