import { useSelectedShop, useQueryWrapper } from "shared/hooks";
import {
  getActivationTemplates,
  getNewsletterFormsTemplates,
  getNewsletterFormsTranslations,
  getNewsletterFormsInputs,
  getNewsletterFormsPositions
} from "shared/api";
import {
  NEWSLETTER_FORMS_TEMPLATE_POSITIONS,
  getAvailablePositions
} from "./usePreviewTemplates.helpers";
import { useMemo } from "react";

export const usePreviewTemplates = () => {
  const { selectedShop, selectedShopId } = useSelectedShop();

  const { data: activationData, isLoading: activationLoading } = useQueryWrapper(
    ["activationTemplates", selectedShop],
    async () => await getActivationTemplates(selectedShop!),
    {
      enabled: !!selectedShop
    }
  );

  const { data: optinData, isLoading: optinLoading } = useQueryWrapper(
    ["optinTemplates", selectedShopId],
    async () => await getNewsletterFormsTemplates(selectedShopId!),
    {
      enabled: !!selectedShopId
    }
  );

  const { data: optinTranslationsData, isLoading: optinTranslationsLoading } = useQueryWrapper(
    ["optinTranslations", selectedShopId],
    async () => await getNewsletterFormsTranslations(selectedShopId!),
    {
      enabled: !!selectedShopId
    }
  );

  const { data: optinInputs, isLoading: optinInputsLoading } = useQueryWrapper(
    ["optinInputs", selectedShop],
    async () => await getNewsletterFormsInputs(selectedShop!),
    {
      enabled: !!selectedShop
    }
  );

  const { data: optinPositions, isLoading: optinPositionsLoading } = useQueryWrapper(
    ["optinPositions", selectedShopId],
    async () => await getNewsletterFormsPositions(selectedShopId!),
    {
      enabled: !!selectedShop
    }
  );

  const activationResult = activationData?.data ?? [];

  const optinResult = optinData?.items ?? [];

  const optinTranslationsResult = optinTranslationsData?.items ?? [];

  const optinInputsResult = optinInputs?.data ?? [];

  const optinPositionsResult = useMemo(() => {
    return optinPositions
      ? {
          desktop: optinPositions.desktop,
          mobile: optinPositions.mobile,
          availablePositions: getAvailablePositions(optinPositions.allowedOptions)
        }
      : NEWSLETTER_FORMS_TEMPLATE_POSITIONS;
  }, [optinPositions]);

  const resultLoading =
    activationLoading ||
    optinLoading ||
    optinTranslationsLoading ||
    optinInputsLoading ||
    optinPositionsLoading;

  const activationLanguages = activationResult
    .reduce((prev, template) => {
      const language = template.language;

      if (!prev.includes(language)) {
        prev.push(language);
      }

      return prev;
    }, [] as string[])
    .sort();

  const optinLanguages = optinTranslationsResult
    .reduce((prev, template) => {
      const language = template.meta.language;

      if (!prev.includes(language)) {
        prev.push(language);
      }

      return prev;
    }, [] as string[])
    .sort();

  return {
    activationTemplates: activationResult,
    optinTemplates: optinResult,
    optinTranslations: optinTranslationsResult,
    optinInputs: optinInputsResult,
    optinPositions: optinPositionsResult,
    activationLanguages,
    optinLanguages,
    loading: resultLoading
  };
};
