import { KeyTitlePair } from "shared/types";

export const FONT_FAMILIES: KeyTitlePair[] = [
  { key: "Arial", title: "Arial" },
  { key: "Helvetica", title: "Helvetica" },
  { key: "Times New Roman", title: "Times New Roman" },
  { key: "Times", title: "Times" },
  { key: "Courier New", title: "Courier New" },
  { key: "Courier", title: "Courier" },
  { key: "Verdana", title: "Verdana" },
  { key: "Georgia", title: "Georgia" },
  { key: "Palatino", title: "Palatino" },
  { key: "Garamond", title: "Garamond" },
  { key: "Bookman", title: "Bookman" },
  { key: "Comic Sans MS", title: "Comic Sans MS" },
  { key: "Trebuchet MS", title: "Trebuchet MS" },
  { key: "Arial Black", title: "Arial Black" },
  { key: "Impact", title: "Impact" }
];
