import { apiClient } from "shared/utils";
import { QuestionnaireAboutUsStepData, ShopIdParam } from "api/models";

export const putSubmitAboutUs = async ({
  shopId,
  ...values
}: QuestionnaireAboutUsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/about-us`, values);

  return data;
};
