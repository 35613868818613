import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormApi } from "final-form";
import { useCallback } from "react";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { deleteComeBackMailingText } from "../../api";
import { ComeBackMailingTextSettingsValues } from "../../types";

export const useDeleteComeBackMailingText = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: deleteComeBackMailingTextMutation } = useMutation<
    void,
    AxiosError<{ message: string }>,
    { shopId: number; id: number }
  >(deleteComeBackMailingText);

  const executeDeleteComeBackMailingText = useCallback(
    async (id: number, form: FormApi<ComeBackMailingTextSettingsValues>) => {
      try {
        await deleteComeBackMailingTextMutation({
          id,
          shopId: selectedShopId!
        });

        queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);
        queryClient.invalidateQueries(["comeBackMailingConfig", selectedShopId]);
        queryClient.invalidateQueries(["comeBackMailingCanEnable", selectedShopId]);

        form.reset();

        showNotification("featuresModule.comeBackMailing.deleteSuccessMessage", "success");
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [queryName, selectedShopId, limit, offset, showNotification]
  );

  return executeDeleteComeBackMailingText;
};
