import { useCallback } from "react";
import {
  ContainedButton,
  SelectDropdown,
  TextInput,
  FieldLine,
  LoadingIndicator
} from "shared/components";
import { Form, Field, FormProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { PaymentSettings } from "api/models";
import { PAYMENT_TYPES } from "config/constants";
import { Container, Title } from "./TriggerMailsPaymentSettingsFormBase.styles";
import { rateValidator } from "./TriggerMailsPaymentSettingsFormBase.validation";

export const TriggerMailsPaymentSettingsFormBase = ({
  translationKey,
  initialValues,
  onSubmit,
  loading
}: {
  initialValues: PaymentSettings;
  translationKey: string;
  onSubmit: FormProps<PaymentSettings, PaymentSettings>["onSubmit"];
  loading?: boolean;
}) => {
  const { t } = useTranslation();

  const handleFormSubmit: FormProps<PaymentSettings, PaymentSettings>["onSubmit"] = useCallback(
    (values, form) => {
      return onSubmit(values, form);
    },
    [onSubmit]
  );

  return (
    <Container>
      <Title>{t(`settingsModule.triggerMailsPayment.${translationKey}.title`)}</Title>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Form
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          render={({ dirty, handleSubmit, submitErrors, submitting }) => (
            <>
              <FieldLine>
                <Field
                  name="paymentType"
                  render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                    <SelectDropdown
                      options={PAYMENT_TYPES}
                      label={t(
                        `settingsModule.triggerMailsPayment.${translationKey}.form.type.label`
                      )}
                      placeholder={t(
                        `settingsModule.triggerMailsPayment.${translationKey}.form.type.placeholder`
                      )}
                      {...input}
                      error={
                        (error || (submitErrors?.["paymentType"] && !modifiedSinceLastSubmit)) &&
                        touched
                      }
                      errorMessage={t(submitErrors?.["paymentType"] ?? error)}
                      marginBottom={25}
                    />
                  )}
                />
                <Field
                  name="rate"
                  validate={rateValidator}
                  render={({
                    input: { value, onChange, onBlur, ...input },
                    meta: { error, touched, modifiedSinceLastSubmit }
                  }) => (
                    <TextInput
                      label={t(
                        `settingsModule.triggerMailsPayment.${translationKey}.form.rate.label`
                      )}
                      placeholder={t(
                        `settingsModule.triggerMailsPayment.${translationKey}.form.rate.placeholder`
                      )}
                      {...input}
                      value={value ? value : ""}
                      onChange={(value) => {
                        const numericOnly = value.replace(/[^0-9.]/g, "");
                        const roundedDecimals =
                          numericOnly.match(/^-?\d+(?:\.\d{0,2})?/)?.[0] ?? "";

                        onChange(roundedDecimals);
                      }}
                      onBlur={() => {
                        onChange(Number(value));
                        onBlur();
                      }}
                      error={
                        (error || (submitErrors?.["rate"] && !modifiedSinceLastSubmit)) && touched
                      }
                      errorMessage={t(submitErrors?.["rate"] ?? error)}
                      marginBottom={25}
                    />
                  )}
                />
              </FieldLine>
              <ContainedButton
                type="submit"
                disabled={!dirty}
                loading={submitting}
                onClick={handleSubmit}
              >
                {t(`settingsModule.triggerMailsPayment.${translationKey}.form.saveButton`)}
              </ContainedButton>
            </>
          )}
        />
      )}
    </Container>
  );
};
