import { useCallback, useMemo } from "react";
import {
  Currencies,
  QuestionnaireBrowseAbandonmentVoucherSettingsStepData,
  QuestionnaireStepType,
  VoucherTypes
} from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { useStepsResolver } from "../useStepsResolver";
import { putSubmitBrowseAbandonmentVoucherSettings } from "../../api";
import {
  QuestionnaireBrowseAbandonmentVoucherSettingsFormValues,
  VoucherListControlMode
} from "../../types";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";
import { browseAbandonmentVoucherSettingsFormMapper } from "./useBrowseAbandonmentVoucherSettingsStep.helpers";

export const useBrowseAbandonmentVoucherSettingsStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.BROWSE_ABANDONMENT_VOUCHER_SETTINGS);

  const data: QuestionnaireBrowseAbandonmentVoucherSettingsFormValues = useMemo(
    () => ({
      ...stepData?.data,
      voucherValueType: stepData?.data?.voucherValueType || Currencies.PERCENTAGE,
      voucherKind: stepData?.data?.voucherKind || VoucherTypes.CODE,
      voucherListControlMode:
        stepData?.data?.listName && stepData?.data?.voucherKind === VoucherTypes.LIST
          ? VoucherListControlMode.SELECT
          : VoucherListControlMode.CREATE
    }),
    [stepData?.data]
  );

  const submitBrowseAbandonmentVoucherSettings =
    useQuestionnaireSubmitMutation<QuestionnaireBrowseAbandonmentVoucherSettingsStepData>(
      putSubmitBrowseAbandonmentVoucherSettings
    );

  const executeSubmitBrowseAbandonmentVoucherSettings: FormProps<
    QuestionnaireBrowseAbandonmentVoucherSettingsFormValues,
    QuestionnaireBrowseAbandonmentVoucherSettingsFormValues
  >["onSubmit"] = useCallback(
    async (formValues) => {
      try {
        const browseAbandonmentVoucherSettingsSubmitData =
          browseAbandonmentVoucherSettingsFormMapper(formValues);

        await submitBrowseAbandonmentVoucherSettings(browseAbandonmentVoucherSettingsSubmitData);
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitBrowseAbandonmentVoucherSettings]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitBrowseAbandonmentVoucherSettings
  };
};
