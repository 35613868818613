import { apiClient } from "../utils";
import { GetActivationTemplatesResponse } from "api/models";

export const getActivationTemplates = async (mongoShopId: string) => {
  const { data } = await apiClient.get<GetActivationTemplatesResponse>(
    `/shop/${mongoShopId}/activationTemplates`
  );

  return data;
};
