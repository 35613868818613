import module from "module";
import { Routes } from "config/navigation";
import { PageWrapper, NavigationBar, PublicFooter } from "shared/components";
import { ContentWrapper, SetPasswordForm } from "../components";

const SetPasswordPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="public" />
      <ContentWrapper>
        <SetPasswordForm />
        <PublicFooter />
      </ContentWrapper>
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.SET_PASSWORD
});

export default SetPasswordPage;
