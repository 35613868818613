import { Heading, Subheading, DescriptionText, LoadingIndicator } from "shared/components";
import { useTranslation } from "react-i18next";
import { FormWrapper } from "./ContactPersonsList.styles";
import { useContactPersonListData } from "../../hooks";
import { ContactPersonItemForm } from "../ContactPersonItemForm";
import { CreateContactPersonForm } from "../CreateContactPersonForm";
import { DEFAULT_QUERY_LIMIT } from "config/constants";

export const ContactPersonList = () => {
  const { t } = useTranslation();

  const { initialState, loading } = useContactPersonListData();

  const contactPersonsCountThresholdMet = initialState.length >= DEFAULT_QUERY_LIMIT;

  return (
    <FormWrapper>
      <Heading>{t("settingsModule.contactPersons.heading")}</Heading>
      <Subheading>{t("settingsModule.contactPersons.subheading")}</Subheading>
      <DescriptionText withMargin>{t("settingsModule.contactPersons.description")}</DescriptionText>
      {loading ? (
        <LoadingIndicator />
      ) : (
        initialState.map((contactPerson) => (
          <ContactPersonItemForm key={contactPerson.contactPersonId} initialState={contactPerson} />
        ))
      )}
      <CreateContactPersonForm disabled={loading || contactPersonsCountThresholdMet} />
    </FormWrapper>
  );
};
