import { PaymentSettings } from "api/models";
import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared/hooks";
import { getBrowseAbandonmentPayment } from "../../api";

export const useGetBrowseAbandonmentPayment = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQueryWrapper<PaymentSettings>(
    [queryName, selectedShopId],
    async () => await getBrowseAbandonmentPayment(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const resultData = data ?? {};

  return { initialState: resultData, loading: isLoading };
};
