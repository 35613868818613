import { FieldValidator } from "final-form";
import { isEmptyString } from "shared/utils/validation";
import { VoucherTypes } from "api/models";
import { QuestionnaireComeBackMailingVoucherSettingsFormValues } from "../../../types";

export const voucherValueValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "questionnaireModule.errors.shouldBeANumber";
  }

  if (numberValue < 0.1) {
    return "questionnaireModule.errors.tooSmall";
  }
};

export const voucherValueTypeValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }
};

export const voucherCodeValidator: FieldValidator<string> = (value, form) => {
  if (
    (form as QuestionnaireComeBackMailingVoucherSettingsFormValues).voucherKind ===
      VoucherTypes.CODE &&
    isEmptyString(value)
  ) {
    return "questionnaireModule.errors.required";
  }
};
