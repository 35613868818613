import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormProps } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { RegisterResponse } from "api/models";
import { postRegister } from "../../api";
import { RegisterValues } from "../../types";

export const useRegisterMutation = (token: string | null) => {
  const { mutateAsync } = useMutation<
    RegisterResponse,
    AxiosError<{ message: string }>,
    RegisterValues
  >(postRegister);

  const execute: FormProps<RegisterValues, RegisterValues>["onSubmit"] = async (values) => {
    try {
      await mutateAsync({
        email: values.email.toLowerCase(),
        registrationToken: token ?? values.registrationToken
      });
    } catch (err) {
      const error = err as AxiosError<{ message: string }>;

      let errorTranslationKey = "global.error.serverUnavailable";

      if (error.response?.data.message) {
        errorTranslationKey = error.response?.data.message;
      }

      return {
        [FORM_ERROR]: errorTranslationKey
      };
    }
  };

  return execute;
};
