import { ActivationType, IncentiveType } from "api/models";
import { DeviceType } from "shared/types";

export const PREVIEW_USER_AGENTS: Record<DeviceType, string> = {
  [DeviceType.DESKTOP]:
    "Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.75 Safari/537.36",
  [DeviceType.MOBILE]:
    "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1"
};

export const PREVIEW_DIMENSIONS: Record<DeviceType, { width: number; height: number }> = {
  [DeviceType.DESKTOP]: {
    width: 1440,
    height: 900
  },
  [DeviceType.MOBILE]: {
    width: 320,
    height: 648
  }
};

export const PREVIEW_MOCK_DATA: Record<
  ActivationType | IncentiveType,
  Record<string, string | string[]>
> = {
  [ActivationType.VOUCHER]: {
    value: "X",
    currencySymbol: "X",
    voucherCodeFootnote: "Nur für Neukunden",
    voucherCode: "idf94jf"
  },
  [ActivationType.PASSIVE_MAILS]: {
    contactName: "Max Mustermann",
    mailAddress: "max@mustermann.de",
    mailSubject: "Test Mail",
    mailContent: "This is a test Mail"
  },
  [ActivationType.PHONE]: {
    contactName: "Max Mustermann",
    phoneNumber: "040/04849275"
  },
  [ActivationType.LINK]: {
    linkTitle: "Test Link",
    linkTarget: "_blank",
    linkURL: "https://www.uptain.de/de/rueckgewinnung-von-kaufabbrechern/"
  },
  [IncentiveType.VOUCHERS]: {
    value: "XX",
    currencySymbol: "X",
    voucherCodeFootnote: "Nur für Neukunden",
    voucherCode: "idf94jf",
    trackingOptions: [],
    voucherLists: [],
    voucherName: "TESTLIST01",
    voucherType: "name"
  },
  [IncentiveType.FOMO]: {
    trackingOptions: []
  }
};
