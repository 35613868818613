import { EmailVendor } from "api/models";

export const questionnaireMailVendorTranslationKeys: Record<EmailVendor, string> = {
  [EmailVendor.Google]: "questionnaireModule.steps.integrations.mailSettings.vendors.google",
  [EmailVendor.Namecheap]: "questionnaireModule.steps.integrations.mailSettings.vendors.namecheap",
  [EmailVendor.Strato]: "questionnaireModule.steps.integrations.mailSettings.vendors.strato",
  [EmailVendor.Outlook]: "questionnaireModule.steps.integrations.mailSettings.vendors.outlook",
  [EmailVendor.Ionos]: "questionnaireModule.steps.integrations.mailSettings.vendors.ionos",
  [EmailVendor.Mailbox]: "questionnaireModule.steps.integrations.mailSettings.vendors.mailbox",
  [EmailVendor.Other]: "questionnaireModule.steps.integrations.mailSettings.vendors.other",
  [EmailVendor.Uptain]: "questionnaireModule.steps.integrations.mailSettings.vendors.uptain"
};

export const questionnaireMailVendorHintsTranslationKeys: Record<EmailVendor, string | undefined> =
  {
    [EmailVendor.Google]: "questionnaireModule.steps.integrations.mailSettings.vendorHints.google",
    [EmailVendor.Namecheap]:
      "questionnaireModule.steps.integrations.mailSettings.vendorHints.namecheap",
    [EmailVendor.Strato]: undefined,
    [EmailVendor.Outlook]: undefined,
    [EmailVendor.Ionos]: undefined,
    [EmailVendor.Mailbox]: undefined,
    [EmailVendor.Other]: undefined,
    [EmailVendor.Uptain]: undefined
  };
