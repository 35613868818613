import { useTranslation } from "react-i18next";
import { Heading, Subheading, DescriptionText, TextInput } from "shared/components";
import { Form, Field } from "react-final-form";
import { StepWrapper, StepsContent, InputsWrappers } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { NavigationButtons } from "../../NavigationButtons";
import { useCustomerSupportStep } from "../../../hooks";
import { emailValidator, nameValidator, phoneValidator } from "./CustomerSupport.validation";

export const CustomerSupport = () => {
  const { t } = useTranslation();

  const {
    data,
    hasPrev,
    firstNameDisabled,
    lastNameDisabled,
    emailDisabled,
    phoneDisabled,
    phoneOptional,
    handlePrevStep,
    executeSubmitCustomerSupport
  } = useCustomerSupportStep();

  const displayFirstLine = !(firstNameDisabled && lastNameDisabled);

  const displaySecondLine = !(emailDisabled && phoneDisabled);

  const firstLineSingleChild = [firstNameDisabled, lastNameDisabled].filter(Boolean).length === 1;

  const secondLineSingleChild = [emailDisabled, phoneDisabled].filter(Boolean).length === 1;

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitCustomerSupport}
      render={({ handleSubmit, submitting, submitErrors }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.customer.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.customer.customerSupport.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.customer.customerSupport.description")}
            </DescriptionText>
            {displayFirstLine && (
              <InputsWrappers singleChild={firstLineSingleChild}>
                {!firstNameDisabled && (
                  <Field
                    name="firstName"
                    validate={nameValidator}
                    render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                      <TextInput
                        required
                        {...input}
                        label={t(
                          "questionnaireModule.steps.customer.customerSupport.form.firstName.label"
                        )}
                        placeholder={t(
                          "questionnaireModule.steps.customer.customerSupport.form.firstName.placeholder"
                        )}
                        error={
                          (error || (submitErrors?.["firstName"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["firstName"] ?? error)}
                        marginBottom={36}
                      />
                    )}
                  />
                )}
                {!lastNameDisabled && (
                  <Field
                    name="lastName"
                    validate={nameValidator}
                    render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                      <TextInput
                        required
                        {...input}
                        label={t(
                          "questionnaireModule.steps.customer.customerSupport.form.lastName.label"
                        )}
                        placeholder={t(
                          "questionnaireModule.steps.customer.customerSupport.form.lastName.placeholder"
                        )}
                        error={
                          (error || (submitErrors?.["lastName"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["lastName"] ?? error)}
                        marginBottom={36}
                      />
                    )}
                  />
                )}
              </InputsWrappers>
            )}
            {displaySecondLine && (
              <InputsWrappers singleChild={secondLineSingleChild}>
                {!emailDisabled && (
                  <Field
                    name="email"
                    validate={emailValidator}
                    render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                      <TextInput
                        required
                        {...input}
                        label={t(
                          "questionnaireModule.steps.customer.customerSupport.form.email.label"
                        )}
                        placeholder={t("global.placeholders.email")}
                        error={
                          (error || (submitErrors?.["email"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["email"] ?? error)}
                      />
                    )}
                  />
                )}
                {!phoneDisabled && (
                  <Field
                    name="phone"
                    validate={(value) => phoneValidator(value, phoneOptional)}
                    render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                      <TextInput
                        required={!phoneOptional}
                        {...input}
                        label={t(
                          "questionnaireModule.steps.customer.customerSupport.form.phone.label"
                        )}
                        placeholder={t(
                          "questionnaireModule.steps.customer.customerSupport.form.phone.placeholder"
                        )}
                        error={
                          (error || (submitErrors?.["phone"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["phone"] ?? error)}
                      />
                    )}
                  />
                )}
              </InputsWrappers>
            )}
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
