import { GetBrowseAbandonmentCanEnableResponse } from "api/models";
import { apiClient } from "shared/utils";

export const getBrowseAbandonmentCanEnable = async (
  shopId: number
): Promise<GetBrowseAbandonmentCanEnableResponse> => {
  const { data } = await apiClient.get<GetBrowseAbandonmentCanEnableResponse>(
    `/${shopId}/triggers/browse-abandonment/can-enable`
  );

  return data;
};
