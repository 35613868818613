import { apiClient } from "shared/utils";
import { PaymentSettings } from "api/models";

export const getComeBackMailingPayment = async (shopId: number) => {
  const { data } = await apiClient.get<PaymentSettings>(
    `${shopId}/triggers/come-back-mailing/payment`
  );

  return data;
};
