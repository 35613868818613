import { useTranslation, Trans } from "react-i18next";
import { Heading, Subheading, DescriptionText, Checkbox, Link } from "shared/components";
import { useSupportContacts } from "shared/hooks";
import { Form, Field } from "react-final-form";
import { confirmedValidator } from "./AboutUs.validation";
import { StepWrapper, StepsContent, IntroductionCheckboxWrapper } from "../../StepStyles";
import { MobileStepper } from "../../Stepper";
import { VideoPlayer } from "../../VideoPlayer";
import { NavigationButtons } from "../../NavigationButtons";
import { useQuestionnaireVideos, useAboutUsStep } from "../../../hooks";

export const AboutUs = () => {
  const { t } = useTranslation();

  const { aboutUs } = useQuestionnaireVideos();

  const { email, phone } = useSupportContacts();

  const { data, hasPrev, handlePrevStep, executeSubmitAboutUs } = useAboutUsStep();

  return (
    <Form
      initialValues={data}
      onSubmit={executeSubmitAboutUs}
      render={({ handleSubmit, submitErrors, submitting }) => (
        <StepWrapper>
          <StepsContent>
            <Heading>{t("questionnaireModule.steps.introduction.title")}</Heading>
            <Subheading>{t("questionnaireModule.steps.introduction.aboutUs.title")}</Subheading>
            <DescriptionText>
              {t("questionnaireModule.steps.introduction.aboutUs.description")}
            </DescriptionText>
            <VideoPlayer
              title={t("questionnaireModule.steps.introduction.aboutUs.videoTitle")}
              src={aboutUs}
            />
            <Field
              name="isConfirmed"
              validate={confirmedValidator}
              type="checkbox"
              render={({ input, meta: { error, touched, modifiedSinceLastSubmit } }) => (
                <IntroductionCheckboxWrapper>
                  <Checkbox
                    label={
                      <Trans
                        i18nKey={"questionnaireModule.steps.introduction.aboutUs.checkboxLabel"}
                        t={t}
                        values={{
                          email,
                          phone
                        }}
                        components={{
                          email: <Link href={`mailto:${email}`} />,
                          phone: <Link href={`tel:${phone}`} />
                        }}
                      />
                    }
                    {...input}
                    error={
                      (error || (submitErrors?.["isConfirmed"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    marginBottom={36}
                  />
                </IntroductionCheckboxWrapper>
              )}
            />
            <NavigationButtons
              prevDisabled={!hasPrev}
              nextLoading={submitting}
              onNextClick={handleSubmit}
              onPrevClick={handlePrevStep}
            />
          </StepsContent>
          <MobileStepper
            prevDisabled={!hasPrev}
            nextLoading={submitting}
            onNextClick={handleSubmit}
            onPrevClick={handlePrevStep}
          />
        </StepWrapper>
      )}
    />
  );
};
