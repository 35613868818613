import { Field, Form } from "react-final-form";
import {
  ContainedButton,
  DescriptionText,
  Heading,
  LoadingIndicator,
  Subheading,
  Switch,
  TextInput
} from "shared/components";
import { Permission } from "shared/types";
import { useAuthState } from "shared/hooks";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  AdministrationBlockWrapper,
  AdministrationButtonWrapper,
  AdministrationStatusWrapper,
  HeadingFormButtonWrapper,
  HeadingFormInputWrapper,
  HeadingFormLoadingWrapper,
  HeadingFormSubheadingWrapper,
  HeadingFormWaitingTimeWrapper,
  HeadingFormWrapper,
  SwitchWrapper,
  SwitchLabel
} from "../TriggerSettingsFormStyles";
import {
  useBrowseAbandonmentCanEnable,
  useBrowseAbandonmentConfigData,
  useBrowseAbandonmentExistingSessions,
  useBrowseAbandonmentReprocessStatus,
  useDisableBrowseAbandonmentConfig,
  useEnableBrowseAbandonmentConfig,
  useUpdateBrowseAbandonmentConfig
} from "../../hooks";
import { minimumWaitingHoursValidator } from "./BrowseAbandonmentContentHeadingForm.validation";
import { ReprocessStatusIcon } from "../ReprocessStatusIcon";

export const BrowseAbandonmentContentHeadingForm = () => {
  const { getRole } = useAuthState();

  const { t } = useTranslation();

  const { canEnable, loading: loadingCanEnable } = useBrowseAbandonmentCanEnable();

  const { initialState, loading: loadingConfigData } = useBrowseAbandonmentConfigData();

  const loading = loadingCanEnable || loadingConfigData;

  const { initialState: reprocessStatusInitialState, loading: reprocessStatusLoading } =
    useBrowseAbandonmentReprocessStatus();

  const update = useUpdateBrowseAbandonmentConfig();

  const enable = useEnableBrowseAbandonmentConfig();

  const disable = useDisableBrowseAbandonmentConfig();

  const { executeEnableBrowseAbandonmentConfig: reprocess, loading: reprocessLoading } =
    useBrowseAbandonmentExistingSessions();

  const handleSwitchClick = useCallback(() => {
    if (initialState?.enabled) {
      disable();
    } else {
      enable();
    }
  }, [initialState?.enabled, enable, disable]);

  const showAdministrationBlock = getRole() === Permission.ADMIN || getRole() === Permission.SALES;

  const scheduled = reprocessStatusInitialState?.scheduled;

  const scheduledTooltip = scheduled
    ? t("featuresModule.browseAbandonment.administration.scheduledTooltip")
    : t("featuresModule.browseAbandonment.administration.notScheduledTooltip");

  const reprocessButtonTitle = scheduled
    ? t("featuresModule.browseAbandonment.administration.scheduledTooltip")
    : t("featuresModule.browseAbandonment.administration.reprocess");

  return (
    <Form
      initialValues={initialState}
      onSubmit={update}
      render={({ handleSubmit, submitting, dirty, submitErrors }) => (
        <>
          <Heading>{t("featuresModule.browseAbandonment.heading")}</Heading>
          <HeadingFormSubheadingWrapper>
            <Subheading>{t("featuresModule.browseAbandonment.subheading")}</Subheading>
            <SwitchWrapper>
              <Switch
                disabled={!canEnable}
                checked={initialState?.enabled || false}
                color={initialState?.enabled ? "default" : "error"}
                onClick={handleSwitchClick}
              />
              <SwitchLabel>
                {t(
                  `featuresModule.browseAbandonment.featureSwitch.${
                    initialState?.enabled ? "active" : "inactive"
                  }`
                )}
              </SwitchLabel>
            </SwitchWrapper>
          </HeadingFormSubheadingWrapper>
          <DescriptionText>{t("featuresModule.browseAbandonment.description")}</DescriptionText>
          {loading ? (
            <HeadingFormLoadingWrapper>
              <LoadingIndicator />
            </HeadingFormLoadingWrapper>
          ) : (
            <HeadingFormWrapper>
              <HeadingFormWaitingTimeWrapper>
                <Field
                  name="minimumWaitingHours"
                  validate={minimumWaitingHoursValidator}
                  render={({
                    input: { onChange, value, ...input },
                    meta: { error, touched, modifiedSinceLastSubmit }
                  }) => (
                    <HeadingFormInputWrapper>
                      <TextInput
                        {...input}
                        label={t("featuresModule.browseAbandonment.form.minimumWaitingHours.label")}
                        placeholder={t(
                          "featuresModule.browseAbandonment.form.minimumWaitingHours.placeholder"
                        )}
                        marginBottom={24}
                        value={value ? value : ""}
                        onChange={(value) => {
                          onChange(Number(value.replace(/[^0-9]/g, "")));
                        }}
                        error={
                          (error ||
                            (submitErrors?.["minimumWaitingHours"] && !modifiedSinceLastSubmit)) &&
                          touched
                        }
                        errorMessage={t(submitErrors?.["minimumWaitingHours"] ?? error)}
                      />
                    </HeadingFormInputWrapper>
                  )}
                />
                <HeadingFormButtonWrapper>
                  <ContainedButton
                    type="submit"
                    disabled={!dirty}
                    loading={submitting}
                    onClick={handleSubmit}
                  >
                    {t("featuresModule.saveButton")}
                  </ContainedButton>
                </HeadingFormButtonWrapper>
              </HeadingFormWaitingTimeWrapper>
            </HeadingFormWrapper>
          )}
          {showAdministrationBlock && (
            <AdministrationBlockWrapper>
              <AdministrationStatusWrapper>
                <Subheading>
                  {t("featuresModule.browseAbandonment.administration.header")}
                </Subheading>
                {!reprocessStatusLoading && (
                  <ReprocessStatusIcon scheduled={scheduled} tooltip={scheduledTooltip} />
                )}
              </AdministrationStatusWrapper>
              <DescriptionText>
                {t("featuresModule.browseAbandonment.administration.reprocessDescription")}
              </DescriptionText>
              <AdministrationButtonWrapper>
                <ContainedButton
                  disabled={loading || scheduled}
                  loading={reprocessLoading || reprocessStatusLoading}
                  onClick={reprocess}
                >
                  {reprocessButtonTitle}
                </ContainedButton>
              </AdministrationButtonWrapper>
            </AdministrationBlockWrapper>
          )}
        </>
      )}
    />
  );
};
