import { apiClient } from "../utils";
import { GetNewsletterFormsTemplatesResponse } from "api/models";

export const getNewsletterFormsTemplates = async (shopId: number) => {
  const { data } = await apiClient.get<GetNewsletterFormsTemplatesResponse>(
    `/${shopId}/newsletter-forms/templates`
  );

  return data;
};
