/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

import { localStorage } from 'shared/utils';

import { LocalStorageKey } from 'shared/types';

export const DEFAULT_LOCALE = 'en';

export const appLocales = ['en', 'de'];

export const getBrowserLanguage = () => {
  const language =
    localStorage.getItem(LocalStorageKey.LANGUAGE) ||
    navigator.language ||
    navigator.userLanguage;

  return appLocales.includes(language.substring(0, 2))
    ? language.substring(0, 2)
    : undefined;
};
