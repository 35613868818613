import { apiClient } from "shared/utils";
import { QuestionnaireProductsStepData, ShopIdParam } from "api/models";

export const putSubmitProducts = async ({
  shopId,
  ...values
}: QuestionnaireProductsStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/products`, values);

  return data;
};
