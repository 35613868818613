import { useCallback, useEffect, useMemo, useRef } from "react";
import { RenderRequestData } from "api/models";
import {
  Wrapper,
  LaptopDisplay,
  MobileDisplay,
  DesktopWrapperGraphics,
  MobileWrapperGraphics,
  LoadingWrapper
} from "./PreviewComponent.styles";
import { PreviewComponentProps } from "./PreviewComponent.props";
import { DeviceType } from "../../../../types";
import { useRenderer, useWebsiteImage } from "../../hooks";
import { LoadingIndicator } from "../../../utils";
import { throttle } from "lodash";

export const PreviewComponent = ({
  url,
  device,
  design,
  settings,
  previewProduct,
  template: { html, less, js }
}: PreviewComponentProps) => {
  const frameRef = useRef<HTMLIFrameElement>(null);

  const { image, loading } = useWebsiteImage(device, url);

  const requestSettings = useMemo(
    () => ({
      ...settings,
      settings: { ...settings, preview: true },
      design: {
        ...design,
        // If the user has selected "other" as the font, we need to use the manual font input
        // and trim the value to remove any whitespace in before or after font name
        font:
          design.font === "other" ? (design.manualFont as string | undefined)?.trim() : design.font,
        fontBold:
          design.fontBold === "other"
            ? (design.manualFontBold as string | undefined)?.trim()
            : design.fontBold
      }
    }),
    [settings, design]
  );

  const render = useRenderer(frameRef);

  const throttledRender = useCallback(
    throttle(render, 2000),
    // listen to loading to track iframe mounting
    [render, loading]
  );

  useEffect(() => {
    if (!render) return;

    throttledRender(
      html,
      less,
      js,
      requestSettings as RenderRequestData,
      device,
      requestSettings.design,
      previewProduct
    );
  }, [previewProduct, html, less, js, device, requestSettings, throttledRender]);

  const WrapperGraphics =
    device === DeviceType.DESKTOP ? DesktopWrapperGraphics : MobileWrapperGraphics;

  const Display = device === DeviceType.DESKTOP ? LaptopDisplay : MobileDisplay;

  return (
    <Wrapper device={device}>
      <WrapperGraphics />
      {loading ? (
        <LoadingWrapper device={device}>
          <LoadingIndicator />
        </LoadingWrapper>
      ) : (
        <Display ref={frameRef} background={image ?? ""} />
      )}
    </Wrapper>
  );
};
