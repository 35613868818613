import { apiClient } from "shared/utils";
import { QuestionnaireCustomerSupportAvailabilityStepData, ShopIdParam } from "api/models";

export const putSubmitCustomerSupportAvailability = async ({
  shopId,
  ...values
}: QuestionnaireCustomerSupportAvailabilityStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/support-availability`, values);

  return data;
};
