import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postBrowseAbandonmentReprocessExistingSessions } from "../../api";

export const useBrowseAbandonmentExistingSessions = () => {
  const { queryName } = useQueryConfig();

  const queryClient = useQueryClient();

  const { selectedShopId } = useSelectedShop();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: enableBrowseAbandonmentConfig, isLoading } = useMutation<
    void,
    AxiosError<{ message: string }>,
    number
  >(postBrowseAbandonmentReprocessExistingSessions);

  const executeEnableBrowseAbandonmentConfig = useCallback(async () => {
    try {
      await enableBrowseAbandonmentConfig(selectedShopId!);

      queryClient.invalidateQueries([`${queryName}-reprocess-status`, selectedShopId]);

      showNotification("featuresModule.browseAbandonment.administration.successMessage", "success");
    } catch (error) {
      return formatError(error as NetworkError);
    }
  }, [selectedShopId, showNotification, queryName]);

  return { executeEnableBrowseAbandonmentConfig, loading: isLoading };
};
