import { Alert, ContainedButton, Divider, Link, TextInput } from "shared/components";
import { Field } from "react-final-form";
import { Trans, useTranslation } from "react-i18next";
import { VendorFormVariantProps } from "./VendorFormVariant.props";
import { InputsWrappers, ValueAlertWrapper } from "../../../../StepStyles";
import {
  passwordValidator,
  senderAddressValidator,
  senderNameValidator,
  usernameValidator
} from "../../MailSettings.validation";
import { TestButtonWrapper } from "../../MailSettings.styles";
import { questionnaireMailVendorHintsTranslationKeys } from "../../../../../config";
import { useQuestionnaireVendorLinks } from "../../../../../hooks";
import { VendorDividerWrapper } from "./VendorFormVariant.styles";

export const VendorFormVariant = ({
  vendor,
  submitErrors,
  testLoading,
  testResult,
  handleTestMailSettings
}: VendorFormVariantProps) => {
  const { t } = useTranslation();

  const hintKey = questionnaireMailVendorHintsTranslationKeys[vendor];

  const hintLinks = useQuestionnaireVendorLinks();

  return (
    <>
      {hintKey && (
        <Alert severity="info">
          <Trans
            i18nKey={hintKey}
            t={t}
            components={[<Link key={vendor} href={hintLinks[vendor]} target="_blank" />]}
          />
        </Alert>
      )}
      <VendorDividerWrapper withHint={!!hintKey}>
        <Divider />
      </VendorDividerWrapper>
      <InputsWrappers>
        <Field
          name="senderAddress"
          validate={senderAddressValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderAddress.label"
              )}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderAddress.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.senderAddress.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={
                (error || (submitErrors?.["senderAddress"] && !modifiedSinceLastSubmit)) && touched
              }
              errorMessage={t(submitErrors?.["senderAddress"] ?? error)}
            />
          )}
        />
        <Field
          name="username"
          validate={usernameValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.username.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.username.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.username.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={(error || (submitErrors?.["username"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["username"] ?? error)}
            />
          )}
        />
      </InputsWrappers>
      <InputsWrappers>
        <Field
          name="password"
          validate={passwordValidator}
          render={({
            input: { value, onBlur, onChange, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              password
              label={t("questionnaireModule.steps.integrations.mailSettings.form.password.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.password.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.password.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={(error || (submitErrors?.["password"] && !modifiedSinceLastSubmit)) && touched}
              errorMessage={t(submitErrors?.["password"] ?? error)}
            />
          )}
        />
        <Field
          name="senderName"
          validate={senderNameValidator}
          render={({
            input: { value, onChange, onBlur, onFocus },
            meta: { error, modifiedSinceLastSubmit, touched }
          }) => (
            <TextInput
              label={t("questionnaireModule.steps.integrations.mailSettings.form.senderName.label")}
              placeholder={t(
                "questionnaireModule.steps.integrations.mailSettings.form.senderName.placeholder"
              )}
              infoText={
                <Trans
                  i18nKey="questionnaireModule.steps.integrations.mailSettings.form.senderName.hint"
                  t={t}
                />
              }
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              error={
                (error || (submitErrors?.["senderName"] && !modifiedSinceLastSubmit)) && touched
              }
              errorMessage={t(submitErrors?.["senderName"] ?? error)}
            />
          )}
        />
      </InputsWrappers>
      <TestButtonWrapper>
        <ContainedButton fullWidth loading={testLoading} onClick={handleTestMailSettings}>
          {t("questionnaireModule.steps.integrations.mailSettings.form.testButton")}
        </ContainedButton>
      </TestButtonWrapper>
      {testResult && (
        <ValueAlertWrapper>
          <Alert severity={testResult.success ? "info" : "error"}>
            <Trans
              i18nKey={`questionnaireModule.steps.integrations.mailSettings.testAlert.${
                testResult.success ? "success" : "error"
              }`}
              t={t}
              values={{ error: testResult.message }}
            />
          </Alert>
        </ValueAlertWrapper>
      )}
    </>
  );
};
