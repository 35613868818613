import module from "module";
import { Routes } from "config/navigation";
import { PageWrapper, NavigationBar } from "shared/components";
import { Permission } from "shared/types";
import { TriggerMailsPaymentSettingsList } from "../components";

const TriggerMailsPaymentSettingsPage = () => {
  return (
    <PageWrapper>
      <NavigationBar variant="private" />
      <TriggerMailsPaymentSettingsList />
    </PageWrapper>
  );
};

export const getPageConfig = (): module.ModuleConfig => ({
  path: Routes.TRIGGER_MAILS_PAYMENT_SETTINGS,
  requiredPermission: Permission.SALES
});

export default TriggerMailsPaymentSettingsPage;
