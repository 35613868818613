import { apiClient } from "shared/utils";
import { PaymentSettings } from "api/models";

export const putComeBackMailingPayment = async ({
  shopId,
  rate,
  paymentType
}: PaymentSettings & { shopId: number }) => {
  await apiClient.put<void>(`${shopId}/triggers/come-back-mailing/payment`, {
    rate,
    paymentType
  });
};
