import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import UpSwitchLanguageShop from '../../components/UpSwitchLanguageShop';

import { invertTheme } from 'config/theme';
import { CustomerLoginWrapper, Main } from './styles';

import Header from './Header';
import Footer from './Footer';

const CustomerLogin = ({ children }) => (
  <ThemeProvider theme={invertTheme}>
    <CustomerLoginWrapper>
      <UpSwitchLanguageShop languageSwitch />
      <Main>
        <Header />
        {children}
      </Main>
      <Footer />
    </CustomerLoginWrapper>
  </ThemeProvider>
);

CustomerLogin.propTypes = {
  children: PropTypes.node,
};

export default CustomerLogin;
