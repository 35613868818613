import { useMemo } from "react";
import { QuestionnaireStepStatus } from "api/models";
import { useQuestionnaireData, useSelectedShop } from "shared/hooks";
import { QuestionnaireParsedStep } from "../../types";

const stepsSort = (a: { orderNumber: number }, b: { orderNumber: number }) =>
  a.orderNumber - b.orderNumber;

export const useQuestionnaireSteps = () => {
  const { selectedShopId } = useSelectedShop();

  const { data, isLoading } = useQuestionnaireData(selectedShopId);

  const questionnaireSteps = useMemo(() => data?.steps ?? [], [data?.steps]);

  const questionnaireStatus = data?.status;

  const organizedSteps = useMemo(
    () =>
      questionnaireSteps.reduce((acc, { orderNumber, status, type, category, data, metadata }) => {
        const stepExists = acc.find((step) => step.title === category);
        const stepIndex = acc.findIndex((step) => step.title === category);

        if (stepExists) {
          acc[stepIndex].steps.push({ status, type, orderNumber, data, metadata });
          acc[stepIndex].steps.sort(stepsSort);
        }

        if (!stepExists) {
          acc.push({ title: category, steps: [{ status, type, orderNumber, data, metadata }] });
        }

        acc.sort((a, b) => a.steps[0].orderNumber - b.steps[0].orderNumber);

        return acc;
      }, [] as QuestionnaireParsedStep[]),
    [data, isLoading]
  );

  const firstPendingStep = useMemo(() => {
    const pendingSteps = questionnaireSteps.filter(
      (step) => step.status === QuestionnaireStepStatus.PENDING
    );

    return pendingSteps.sort(stepsSort)[0];
  }, [questionnaireSteps]);

  const enabledSteps = useMemo(
    () =>
      questionnaireSteps.filter(
        (step) =>
          step.status === QuestionnaireStepStatus.PENDING ||
          step.status === QuestionnaireStepStatus.SUBMITTED
      ),
    [questionnaireSteps]
  );

  return {
    organizedSteps,
    firstPendingStep,
    loading: isLoading,
    enabledSteps,
    questionnaireStatus,
    questionnaireSteps
  };
};
