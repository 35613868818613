import { apiClient } from "shared/utils";
import { TriggerReprocessStatus } from "api/models";

export const getBrowseAbandonmentReprocessStatus = async (shopId: number) => {
  const { data } = await apiClient.get<TriggerReprocessStatus>(
    `/${shopId}/triggers/browse-abandonment/reprocess`
  );

  return data;
};
