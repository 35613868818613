import { apiClient } from "shared/utils";
import { BrowseAbandonmentVoucherSettingsPayload } from "../../types";

export const putBrowseAbandonmentVoucher = async ({
  shopId,
  id,
  ...values
}: BrowseAbandonmentVoucherSettingsPayload & { shopId: number; id: number }) => {
  const { data } = await apiClient.put<void>(
    `/${shopId}/triggers/browse-abandonment/voucher-settings/${id}`,
    values
  );

  return data;
};
