import { useCallback, useState } from "react";
import { ReachabilityDayConfig, ReachabilityDays } from "shared/types";
import { DayCircle } from "./DayCircle";
import { TimeInput } from "./TimeInput";
import { ReachabilityProps } from "./Reachability.props";
import {
  Wrapper,
  DaysWrapper,
  TimeInputWrapper,
  TimeInputDelimiter,
  ErrorText
} from "./Reachability.styles";

const defaultReachability: ReachabilityDayConfig = { start: "09:00", end: "18:00" };

export const Reachability = ({
  value,
  onChange,
  onFocus,
  onBlur,
  errors,
  errorText
}: ReachabilityProps) => {
  const [selectedDay, setSelectedDay] = useState<ReachabilityDays | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleValueAdd = useCallback(
    (day: ReachabilityDays) => {
      const daysValues = Object.values(ReachabilityDays);

      const previousDay = daysValues[daysValues.indexOf(day) - 1];

      const previousValue = value[day][value[day].length - 1];

      const previousDayFirstValue = value[previousDay]?.[0];

      // On add new value, we want to copy previous value if it exists, or previous day first value if it exists
      // in other case we want to create new value with default values
      const valueToCreate = previousValue ?? previousDayFirstValue ?? defaultReachability;

      const newValue = {
        ...value,
        [day]: [...value[day], valueToCreate]
      };

      onChange(newValue);
      setSelectedDay(day);
      setSelectedIndex(newValue[day].length - 1);
    },
    [value, onChange]
  );

  const handleDayClick = useCallback(
    (day: ReachabilityDays) => {
      if (value[day].length === 0) {
        handleValueAdd(day);
      } else {
        setSelectedDay(day);
        setSelectedIndex(0);
      }
    },
    [value, onChange]
  );

  const handleValueClick = useCallback((day: ReachabilityDays, index: number) => {
    setSelectedDay(day);
    setSelectedIndex(index);
  }, []);

  const handleStartChange = useCallback(
    (updatedValue: string) => {
      if (selectedDay === null || selectedIndex === null) return;

      const copy = { ...value };

      const currentValue = copy[selectedDay][selectedIndex];

      copy[selectedDay][selectedIndex] = {
        ...currentValue,
        start: updatedValue
      };

      onChange(copy);
    },
    [selectedDay, selectedIndex, value, onChange]
  );

  const handleEndChange = useCallback(
    (updatedValue: string) => {
      if (selectedDay === null || selectedIndex === null) return;

      const copy = { ...value };

      const currentValue = copy[selectedDay][selectedIndex];

      copy[selectedDay][selectedIndex] = {
        ...currentValue,
        end: updatedValue
      };

      onChange(copy);
    },
    [selectedDay, selectedIndex, onChange, value]
  );

  const handleValueDelete = useCallback(
    (day: ReachabilityDays, index: number) => {
      const copy = { ...value };

      copy[day].splice(index, 1);

      onChange(copy);

      if (copy[day].length === 0) {
        setSelectedDay(null);
        setSelectedIndex(null);
      }
    },
    [value, onChange]
  );

  const selectedTime = value[selectedDay ?? ReachabilityDays.Monday][selectedIndex ?? 0];

  const valueSelected =
    selectedDay !== null && selectedIndex !== null && selectedTime !== undefined;

  const hasError = errors !== undefined && Object.keys(errors).length > 0;

  const inputsError = hasError && valueSelected && errors[selectedDay]?.[selectedIndex]?.[0];

  return (
    <>
      <Wrapper>
        <DaysWrapper>
          {Object.values(ReachabilityDays).map((day) => (
            <DayCircle
              key={day}
              day={day}
              active={value[day].length > 0}
              selected={selectedDay === day}
              selectedIndex={selectedIndex}
              error={hasError && Object.keys(errors[day] ?? {}).length > 0}
              errors={hasError ? errors[day] : undefined}
              values={value[day]}
              onDayClick={handleDayClick}
              onValueClick={handleValueClick}
              onValueDeleteClick={handleValueDelete}
              onValueAddClick={handleValueAdd}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          ))}
        </DaysWrapper>
        {valueSelected && (
          <TimeInputWrapper>
            <TimeInput
              labelKey="questionnaireModule.form.reachability.from"
              value={selectedTime.start}
              onChangeValue={handleStartChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={!!inputsError}
            />
            <TimeInputDelimiter />
            <TimeInput
              labelKey="questionnaireModule.form.reachability.to"
              value={selectedTime.end}
              onChangeValue={handleEndChange}
              onFocus={onFocus}
              onBlur={onBlur}
              error={!!inputsError}
            />
          </TimeInputWrapper>
        )}
      </Wrapper>
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </>
  );
};
