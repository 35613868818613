import { apiClient } from "shared/utils";
import { QuestionnaireMailSettingsStepData, PostTestMailSettingsResponse } from "api/models";

export const postTestEmailSettings = async ({
  mongoShopId,
  ...values
}: Omit<QuestionnaireMailSettingsStepData, "alternativeEmail"> & { mongoShopId: string }) => {
  const { data } = await apiClient.post<PostTestMailSettingsResponse>(
    `shop/${mongoShopId}/settings/common/email/test`,
    {
      ...values,
      server: values.host,
      type: "smtp"
    }
  );

  return data;
};
