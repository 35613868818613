import { QuestionnaireStepType } from "api/models";
import {
  AboutUs,
  Algorithm,
  Referrer,
  Account,
  ShopSystem,
  Products,
  FormOfAddress,
  AddOptions,
  Design,
  Signature,
  LegalText,
  CustomerSupport,
  CustomerSupportAvailability,
  AbandonmentMailsWaitingTimeMinMinutes,
  ActivationPopupsVoucherSettings,
  AbandonmentMailsVoucherSettings,
  NewsletterFormsVoucherSettings,
  BrowseAbandonmentVoucherSettings,
  ComebackMailingVoucherSettings,
  MailSettings
} from "../components/Steps";

export const questionnaireSteps: Record<QuestionnaireStepType, JSX.Element> = {
  [QuestionnaireStepType.ABOUT_US]: <AboutUs />,
  [QuestionnaireStepType.ALGORITHM]: <Algorithm />,
  [QuestionnaireStepType.REFERRER]: <Referrer />,
  [QuestionnaireStepType.ACCOUNT]: <Account />,
  [QuestionnaireStepType.PRODUCTS]: <Products />,
  [QuestionnaireStepType.SHOP_SYSTEM]: <ShopSystem />,
  [QuestionnaireStepType.ADD_OPTIONS]: <AddOptions />,
  [QuestionnaireStepType.FORM_OF_ADDRESS]: <FormOfAddress />,
  [QuestionnaireStepType.TEMPLATE_DESIGN]: <Design />,
  [QuestionnaireStepType.SIGNATURE]: <Signature />,
  [QuestionnaireStepType.LEGAL_TEXT]: <LegalText />,
  [QuestionnaireStepType.CUSTOMER_SUPPORT]: <CustomerSupport />,
  [QuestionnaireStepType.CUSTOMER_SUPPORT_AVAILABILITY]: <CustomerSupportAvailability />,
  [QuestionnaireStepType.ABANDONMENT_MAILS_WAITING_TIME_MIN_MINUTES]: (
    <AbandonmentMailsWaitingTimeMinMinutes />
  ),
  [QuestionnaireStepType.ACTIVATION_POPUPS_VOUCHER_SETTINGS]: <ActivationPopupsVoucherSettings />,
  [QuestionnaireStepType.ABANDONMENT_MAILS_VOUCHER_SETTINGS]: <AbandonmentMailsVoucherSettings />,
  [QuestionnaireStepType.NEWSLETTER_FORMS_VOUCHER_SETTINGS]: <NewsletterFormsVoucherSettings />,
  [QuestionnaireStepType.BROWSE_ABANDONMENT_VOUCHER_SETTINGS]: <BrowseAbandonmentVoucherSettings />,
  [QuestionnaireStepType.COME_BACK_MAILING_VOUCHER_SETTINGS]: <ComebackMailingVoucherSettings />,
  [QuestionnaireStepType.MAIL_SETTINGS]: <MailSettings />
};
