import { QuestionnaireTemplateDesignStepData } from "api/models";
import { QuestionnaireTemplateDesignFormState } from "../../types";

export const templateDesignFormMapper = ({
  font,
  fontBold,
  textColor,
  inputColor,
  backgroundColor,
  buttonColor,
  buttonFontColor,
  headingColor,
  manualFont,
  manualFontBold
}: QuestionnaireTemplateDesignFormState): QuestionnaireTemplateDesignStepData => {
  return {
    font: font === "other" ? manualFont?.trim() : font,
    fontBold: fontBold === "other" ? manualFontBold?.trim() : fontBold,
    textColor,
    inputColor,
    backgroundColor,
    buttonColor,
    buttonFontColor,
    headingColor,
    uppercase: false
  };
};
