import { useTheme } from "@mui/material";
import { ContactPerson } from "api/models";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "shared/components";
import { makePaperStyles, Chevron, Autocomplete } from "./ContactPersonSelect.styles";
import { useContactPersonsList } from "../../hooks";
import { ContactPersonSelectProps } from "./ContactPersonSelect.props";

export const ContactPersonSelect = ({
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  errorMessage,
  marginBottom,
  required
}: ContactPersonSelectProps) => {
  const theme = useTheme();

  const [opened, setOpened] = useState(false);

  const { data, loading, refetch } = useContactPersonsList();

  const { t } = useTranslation();

  const handleOpen = useCallback(() => {
    setOpened(true);
    refetch();
    if (onFocus) onFocus();
  }, [refetch, onFocus]);

  const handleClose = useCallback(() => {
    setOpened(false);
    if (onBlur) onBlur();
  }, [onBlur]);

  const handleChange = useCallback(
    (_: SyntheticEvent, value: ContactPerson) => {
      onChange(value);
    },
    [onChange]
  );

  const options = useMemo(() => [...data, value], [data, value]);

  return (
    <Autocomplete
      options={options}
      value={value}
      open={opened}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      loadingText={t("featuresModule.contactPerson.loading")}
      noOptionsText={t("featuresModule.contactPerson.noOptions")}
      loading={loading}
      getOptionLabel={({ title }: ContactPerson) => title}
      isOptionEqualToValue={(option, selected) => {
        if (!data.length) return true;
        return option.contactPersonId === selected.contactPersonId;
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          required={required}
          placeholder={t("featuresModule.contactPerson.placeholder")}
          label={t("featuresModule.contactPerson.label")}
          error={error}
          errorMessage={errorMessage}
          marginBottom={marginBottom}
        />
      )}
      popupIcon={<Chevron />}
      disableClearable
      filterSelectedOptions
      slotProps={{
        paper: {
          sx: makePaperStyles(theme)
        }
      }}
    />
  );
};
