import { GetComeBackMailingVoucherListResponse } from "api/models";
import { apiClient } from "shared/utils";
import { ComeBackMailingVoucherInitialSettingsValues } from "../../types";
import { mapCustomConditionsToForm } from "../../utils";

export const getComeBackMailingVoucherList = async (
  shopId: number,
  limit: number,
  offset: number
) => {
  const { data } = await apiClient.get<GetComeBackMailingVoucherListResponse>(
    `/${shopId}/triggers/come-back-mailing/voucher-settings`,
    {
      params: {
        offset,
        limit
      }
    }
  );

  return {
    ...data,
    items: data.items.map<ComeBackMailingVoucherInitialSettingsValues>(
      ({ comeBackMailingTriggerItemId: id, ...item }) => ({
        ...item,
        id,
        conditions: mapCustomConditionsToForm(item.conditions)
      })
    )
  };
};
