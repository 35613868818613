import { ModuleConfig, Module } from "module";
import { navigationBarConfig } from "config/navigation";
import { NavigationRoute } from "../../types";

export const getNavigationConfigs = (modules: Module[]): ModuleConfig[] => {
  return modules
    .map((module) => module.getPageConfig())
    .filter(({ path }) =>
      navigationBarConfig.some(
        ({ path: p, children }) => p === path || children?.some(({ path: cp }) => cp === path)
      )
    );
};

export const parsePages = (modules: ModuleConfig[]): NavigationRoute[] => {
  return navigationBarConfig.map(
    ({ children, path, includeShopId, requiredPermission, translationKey }) => {
      const pathValue = path ? { path, includeShopId } : undefined;

      let featureFlag;

      if (pathValue) {
        featureFlag = modules.find(({ path: p }) => p === path)?.featureFlag;
      }

      return {
        path: pathValue,
        requiredPermission,
        translationKey,
        featureFlag,
        subroutes: children?.map(
          ({ path, translationKey, includeShopId, requiredPermission, legacy }) => {
            let permission;
            let featureFlag;

            if (legacy) {
              permission = requiredPermission;
            } else {
              const moduleConfig = modules.find(({ path: p }) => p === path);
              permission = moduleConfig?.requiredPermission;
              featureFlag = moduleConfig?.featureFlag;
            }

            return {
              translationKey,
              path: {
                path,
                includeShopId
              },
              requiredPermission: permission,
              featureFlag
            };
          }
        )
      };
    }
  );
};
