import { ButtonGroup, VoucherListSelect, TextInput } from "shared/components";
import { Button } from "@mui/material";
import { Field, FormSpy } from "react-final-form";
import { useTranslation } from "react-i18next";
import {
  ButtonGroupWrapper,
  VoucherFieldWrapper,
  CreateFieldsWrapper
} from "./VoucherListControl.styles";
import { VoucherListControlProps } from "./VoucherListControl.props";
import { VoucherListControlMode } from "../../types";
import {
  listNameValidator,
  newVoucherListNameValidator,
  newVoucherListValuesValidator,
  extendVoucherListValuesValidator
} from "./VoucherListControl.validation";

export const VoucherListControl = ({ submitErrors }: VoucherListControlProps) => {
  const { t } = useTranslation();

  return (
    <VoucherFieldWrapper>
      <ButtonGroupWrapper>
        <Field
          name="voucherListControlMode"
          render={({ input: { value, onChange } }) => (
            <ButtonGroup variant="contained">
              {Object.values(VoucherListControlMode).map((key) => (
                <Button
                  color={value === key ? "success" : "primary"}
                  key={key}
                  onClick={() => onChange(key)}
                  disabled={value === key}
                >
                  {t(`questionnaireModule.voucherListControl.${key}.label`)}
                </Button>
              ))}
            </ButtonGroup>
          )}
        />
      </ButtonGroupWrapper>
      <FormSpy
        subscription={{ values: true }}
        render={({ values: { voucherListControlMode } }) =>
          voucherListControlMode === VoucherListControlMode.CREATE ? (
            <CreateFieldsWrapper>
              <Field
                name="newVoucherListName"
                validate={newVoucherListNameValidator}
                render={({
                  input: { value, onBlur, onChange, onFocus },
                  meta: { modifiedSinceLastSubmit, touched, error }
                }) => (
                  <TextInput
                    label={t(
                      `questionnaireModule.voucherListControl.create.form.newVoucherListName.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.voucherListControl.create.form.newVoucherListName.placeholder`
                    )}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    required
                    error={
                      (error ||
                        (submitErrors?.["newVoucherListName"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["newVoucherListName"] ?? error)}
                  />
                )}
              />
              <Field
                name="newVoucherListValues"
                validate={newVoucherListValuesValidator}
                render={({
                  input: { name, onBlur, onChange, onFocus, value },
                  meta: { modifiedSinceLastSubmit, touched, error }
                }) => (
                  <TextInput
                    inputProps={{
                      style: {
                        maxHeight: "100px",
                        overflow: "hidden auto"
                      }
                    }}
                    label={t(
                      `questionnaireModule.voucherListControl.create.form.newVoucherListValues.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.voucherListControl.create.form.newVoucherListValues.placeholder`
                    )}
                    infoText={t(
                      `questionnaireModule.voucherListControl.create.form.newVoucherListValues.hint`
                    )}
                    value={value ? value : ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    required
                    error={(error || (submitErrors?.[name] && !modifiedSinceLastSubmit)) && touched}
                    errorMessage={t(submitErrors?.[name] ?? error)}
                    multiline
                  />
                )}
              />
            </CreateFieldsWrapper>
          ) : (
            <Field
              name="listName"
              validate={listNameValidator}
              render={({ input, meta: { modifiedSinceLastSubmit, touched, error } }) => (
                <VoucherListSelect
                  {...input}
                  required
                  error={
                    (error || (submitErrors?.["listName"] && !modifiedSinceLastSubmit)) && touched
                  }
                  errorMessage={t(submitErrors?.["listName"] ?? error)}
                />
              )}
            />
          )
        }
      />
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          const showExtendField =
            values.listName && values.voucherListControlMode === VoucherListControlMode.SELECT;

          return (
            showExtendField && (
              <Field
                name="extendVoucherListValues"
                validate={extendVoucherListValuesValidator}
                render={({
                  input: { name, ...input },
                  meta: { modifiedSinceLastSubmit, touched, error }
                }) => (
                  <TextInput
                    inputProps={{
                      style: {
                        maxHeight: "100px",
                        overflow: "hidden auto"
                      }
                    }}
                    label={t(
                      `questionnaireModule.voucherListControl.select.form.extendVoucherValues.label`
                    )}
                    placeholder={t(
                      `questionnaireModule.voucherListControl.select.form.extendVoucherValues.placeholder`
                    )}
                    infoText={t(
                      `questionnaireModule.voucherListControl.select.form.extendVoucherValues.hint`
                    )}
                    {...input}
                    error={(error || (submitErrors?.[name] && !modifiedSinceLastSubmit)) && touched}
                    errorMessage={t(submitErrors?.[name] ?? error)}
                    multiline
                  />
                )}
              />
            )
          );
        }}
      />
    </VoucherFieldWrapper>
  );
};
