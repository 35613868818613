import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useSelectedShop } from "shared/hooks";
import { GetQuestionnaireResponse, QuestionnaireStepStatus, ShopIdParam } from "api/models";
import { useSearchParams } from "react-router-dom";
import { postSubmitQuestionnaire } from "../../api";

export const useQuestionnaireSubmitMutation = <RequestParams>(
  mutationFn: (params: RequestParams & ShopIdParam) => Promise<GetQuestionnaireResponse>
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const { mutateAsync: submitQuestionnaire } = useMutation<
    boolean,
    AxiosError<{ message: string }>,
    ShopIdParam
  >(postSubmitQuestionnaire);

  const { mutateAsync } = useMutation<
    GetQuestionnaireResponse,
    AxiosError<{ message: string }>,
    RequestParams & ShopIdParam
  >(mutationFn, {
    onSuccess: async (data) => {
      queryClient.setQueryData(["questionnaire", selectedShopId], () => data);

      if (!data.steps.some((step) => step.status === QuestionnaireStepStatus.PENDING)) {
        await submitQuestionnaire({ shopId: selectedShopId! });
        await queryClient.invalidateQueries(["questionnaire", selectedShopId]);
        searchParams.delete("step");
        setSearchParams(searchParams);
        return;
      }

      const nextStepType = data.steps
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .find((step) => step.status === QuestionnaireStepStatus.PENDING)?.type;

      if (nextStepType) {
        searchParams.set("step", nextStepType);
        setSearchParams(searchParams);
      }
    }
  });

  const enhancedMutateFunction = useCallback(
    async (params: RequestParams) => {
      return mutateAsync({ ...params, shopId: selectedShopId! });
    },
    [selectedShopId, searchParams, mutateAsync]
  );

  return enhancedMutateFunction;
};
