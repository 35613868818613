import { useCallback } from "react";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { QuestionnaireShopSystemStepData, QuestionnaireStepType } from "api/models";
import { FormProps } from "react-final-form";
import { useIntegrations } from "../useIntegrations";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitShopSystem } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useShopSystemStep = () => {
  const { data: integrationsData, loading: integrationsLoading } = useIntegrations();

  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.SHOP_SYSTEM);

  const data = stepData?.data;

  const submitShopSystem =
    useQuestionnaireSubmitMutation<QuestionnaireShopSystemStepData>(putSubmitShopSystem);

  const executeSubmitShopSystem: FormProps<
    QuestionnaireShopSystemStepData,
    QuestionnaireShopSystemStepData
  >["onSubmit"] = useCallback(
    async ({ shopSystem }) => {
      try {
        await submitShopSystem({
          shopSystem
        });
      } catch (error) {
        return formatError(error as NetworkError);
      }
    },
    [submitShopSystem]
  );

  return {
    data,
    integrationsData,
    loading: integrationsLoading,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitShopSystem
  };
};
