import { useQueryConfig, useQueryWrapper, useSelectedShop } from "shared/hooks";
import { getBrowseAbandonmentConfig } from "../../api";

export const useBrowseAbandonmentConfigData = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const { data, isLoading: configLoading } = useQueryWrapper(
    [queryName, selectedShopId],
    async () => getBrowseAbandonmentConfig(selectedShopId!),
    {
      enabled: !!selectedShopId,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return { initialState: data, loading: configLoading };
};
