import { apiClient } from "shared/utils";
import { QuestionnaireAlgorithmStepData, ShopIdParam } from "api/models";

export const putSubmitAlgorithm = async ({
  shopId,
  ...values
}: QuestionnaireAlgorithmStepData & ShopIdParam) => {
  const { data } = await apiClient.put(`questionnaire/${shopId}/algorithm`, values);

  return data;
};
