import { useCallback, useMemo } from "react";
import {
  QuestionnaireCustomerSupportAvailabilityStepData,
  QuestionnaireStepType
} from "api/models";
import { FormProps } from "react-final-form";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { useQuestionnaireStepperState } from "../useQuestionnaireStepperState";
import { putSubmitCustomerSupportAvailability } from "../../api";
import { useStepsResolver } from "../useStepsResolver";
import { useQuestionnaireSubmitMutation } from "../useQuestionnaireSubmitMutation";

export const useCustomerSupportAvailabilityStep = () => {
  const { prevStep, handlePrevStep } = useQuestionnaireStepperState();

  const formatError = useErrorFormatter();

  const stepData = useStepsResolver(QuestionnaireStepType.CUSTOMER_SUPPORT_AVAILABILITY);

  const data = useMemo(() => ({ availability: { ...stepData?.data } }), [stepData?.data]);

  const submitCustomerSupportAvailability =
    useQuestionnaireSubmitMutation<QuestionnaireCustomerSupportAvailabilityStepData>(
      putSubmitCustomerSupportAvailability
    );

  const executeSubmitCustomerSupportAvailability: FormProps<
    { availability: QuestionnaireCustomerSupportAvailabilityStepData },
    { availability: QuestionnaireCustomerSupportAvailabilityStepData }
  >["onSubmit"] = useCallback(
    async ({ availability }) => {
      try {
        await submitCustomerSupportAvailability(availability);
      } catch (error) {
        return { availability: formatError(error as NetworkError) };
      }
    },
    [submitCustomerSupportAvailability]
  );

  return {
    data,
    hasPrev: !!prevStep,
    handlePrevStep,
    executeSubmitCustomerSupportAvailability
  };
};
