import { QuestionnaireProduct } from "api/models";
import activation from "assets/images/product_icons/activation_popups.png";
import abandonment from "assets/images/product_icons/abandonment_mails.png";
import newsletter from "assets/images/product_icons/newsletter_forms.png";
import browse_abandonment from "assets/images/product_icons/browse_abandonment_mails.png";
import come_back_mails from "assets/images/product_icons/come_back_mails.png";

export const questionnaireProductsConfig: QuestionnaireProduct[] = [
  QuestionnaireProduct.ABANDONMENT_MAILS,
  QuestionnaireProduct.BROWSE_ABANDONMENT,
  QuestionnaireProduct.COME_BACK_MAILING,
  QuestionnaireProduct.ACTIVATION_POPUPS,
  QuestionnaireProduct.NEWSLETTER_FORMS
];

export const questionnaireProductsIconsConfig: Record<QuestionnaireProduct, string> = {
  [QuestionnaireProduct.NEWSLETTER_FORMS]: newsletter,
  [QuestionnaireProduct.ACTIVATION_POPUPS]: activation,
  [QuestionnaireProduct.ABANDONMENT_MAILS]: abandonment,
  [QuestionnaireProduct.BROWSE_ABANDONMENT]: browse_abandonment,
  [QuestionnaireProduct.COME_BACK_MAILING]: come_back_mails
};

export const questionnaireProductsIconsSizesConfig: Record<
  QuestionnaireProduct,
  { width: number; height: number }
> = {
  [QuestionnaireProduct.NEWSLETTER_FORMS]: { width: 95, height: 80 },
  [QuestionnaireProduct.ACTIVATION_POPUPS]: { width: 95, height: 80 },
  [QuestionnaireProduct.ABANDONMENT_MAILS]: { width: 126, height: 79 },
  [QuestionnaireProduct.BROWSE_ABANDONMENT]: { width: 126, height: 79 },
  [QuestionnaireProduct.COME_BACK_MAILING]: { width: 126, height: 79 }
};

export const questionnaireProductsLinksConfig: Record<QuestionnaireProduct, string> = {
  [QuestionnaireProduct.NEWSLETTER_FORMS]: "newsletterForms",
  [QuestionnaireProduct.ACTIVATION_POPUPS]: "activationPopups",
  [QuestionnaireProduct.ABANDONMENT_MAILS]: "abandonmentMails",
  [QuestionnaireProduct.BROWSE_ABANDONMENT]: "triggerMails",
  [QuestionnaireProduct.COME_BACK_MAILING]: "triggerMails"
};
