import { useQueryClient, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { useNotifications, useQueryConfig, useSelectedShop } from "shared/hooks";
import { useErrorFormatter } from "shared/utils";
import { NetworkError } from "shared/types";
import { postBrowseAbandonmentConfigDisable } from "../../api";

export const useDisableBrowseAbandonmentConfig = () => {
  const { queryName } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: disableBrowseAbandonmentConfig } = useMutation<
    void,
    AxiosError<{ message: string }>,
    number
  >(postBrowseAbandonmentConfigDisable);

  const executeDisableBrowseAbandonmentConfig = useCallback(async () => {
    try {
      await disableBrowseAbandonmentConfig(selectedShopId!);

      queryClient.invalidateQueries([queryName, selectedShopId]);

      showNotification("featuresModule.browseAbandonment.deactivatedMessage", "success");
    } catch (error) {
      return formatError(error as NetworkError);
    }
  }, [queryName, selectedShopId, showNotification]);

  return executeDisableBrowseAbandonmentConfig;
};
